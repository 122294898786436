
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Container, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import axios from 'axios';
import * as Yup from 'yup';
// import { Formik } from 'formik';
import { useFormik } from "formik";
import { registerUser, apiError, resetRegisterFlag } from "slices/thunk";
import { useSelector, useDispatch } from "react-redux";

import avatar2 from "../../../assets/images/users/avatar-2.jpg"
import ListViewTable from './listViewTable';
import { v4 as uuidv4 } from 'uuid';
import { useDebounce } from 'use-debounce';

const SellersListView = () => {

   


    const dispatch = useDispatch<any>();
    // const [sellerList, setSellerList] = useState<any[]>([]);

    const [loader, setLoader] = useState<boolean>(false);
    const [sellerList, setSellerList] = useState<any>([]);
    const [allstatus, setStatus] = useState<any>([]);
    const [alldates, setDates] = useState<any>([]);
    const [modalAddSeller, setModalAddSeller] = useState<boolean>(false);
    const [searchdates, setSearchdates] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [statussearchTerm, setstatusSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');
    const [dateFilter, setDateFilter] = useState('All');
    const [statusdate, setstatusdate] =useState<any>([]);;
    // const [statusdate, setstatusdate] =useState<any>([]);;
    const [firstNameFilter, setFirstNameFilter] = useState<string>('');
    const [lastNameFilter, setLastNameFilter] = useState<string>('');
    const [emailFilter, setEmailFilter] = useState<string>('');

    const [debouncedSearchTerm] = useDebounce(searchTerm, 500); 
 
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            last_name: '',
            first_name: '',
            password: '',
            confirm_password: '',
            Phone_number: '',
            Profile_Image: null,
            Telephone_number:'',
            Address:'',
            City:'',
            State:'',
            Zipcode:'',
            Country:'',
            users_status:''


        },
        validationSchema: Yup.object({
            // email: Yup.string().required("Please Enter Your Email"),
            // first_name: Yup.string().required("Please Enter Your First Name"),
            // last_name: Yup.string().required("Please Enter Your Last Name"),
            // password: Yup.string().required("Please Enter Your Password")
            //     .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"),
            // confirm_password: Yup.string().oneOf([Yup.ref('password'), undefined], 'Password must match')
            //     .required("Please Enter Your Password"),
            // Phone_number: Yup.string().required("Please Enter Your Mobile Number")
            //     .matches(/^\d{10}$/, "Phone Number must be 10 digits"),
        }),
        onSubmit: async (values) => {
            console.log( values, "55555")
            setLoader(true);
            try {
                const formData = new FormData();
                Object.keys(values).forEach(key => {
                    if (key === 'Profile_Image' && values[key]) {
                        formData.append(key, values[key]);
                    } else {
                        formData.append(key, (values as any)[key]);
                    }
                });

                const tokenString = localStorage.getItem('authUser');
                let token = '';

                if (tokenString) {
                    const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
                    token = tokenObject.token;
                }

                await axios.post(
                    `${process.env.REACT_APP_API_BASEURL}/auth/signup`,
                    formData,

                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
                setModalAddSeller(false);
                validation.resetForm();
            } catch (error) {
                console.error(error);
            } finally {
                setLoader(false);
            }
        },
    });
console.log(FormData, "formDataformData")
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            validation.setFieldValue('Profile_Image', event.target.files[0]);
        }
    };

console.log(validation, "validationvalidationvalidationvalidationvalidationvalidation" )
    document.title = "List View - Sellers | Artflute + Admin React Template";

    const [modal_AddSellerModals, setmodal_AddSellerModals] = useState<boolean>(false);
    function tog_AddSellerModals() {
        setmodal_AddSellerModals(!modal_AddSellerModals);
    }


    
    const getToken = () => {
        const tokenString = localStorage.getItem('authUser');
        if (tokenString) {
            const tokenObject = JSON.parse(tokenString);
            return tokenObject.token || '';
        }
        return '';
    };

    

    const fetchSellerList = async () => {
        setLoader(true);
        try {
            const token = getToken();
    
            const queryParams = new URLSearchParams({
                search: debouncedSearchTerm, 
                status: statusFilter === 'All' ? '' : statusFilter.toLowerCase(),
                date: dateFilter === 'All' ? '' : dateFilter.toLowerCase()
            }).toString();
    
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_user_details?${queryParams}`, 
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            setSellerList(response.data);
        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };
    
    useEffect(() => {
        fetchSellerList();
    }, [debouncedSearchTerm, statusFilter, dateFilter]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleStatusFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setStatusFilter(event.target.value);
    };

    const handleDateFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDateFilter(event.target.value);
    };

   
    const isDateInRange = (dateString: string, filter: string) => {
        const date = new Date(dateString);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        const last7Days = new Date(today);
        last7Days.setDate(today.getDate() - 7);
        const last30Days = new Date(today);
        last30Days.setDate(today.getDate() - 30);
        const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

        switch (filter) {
            case 'today':
                return date.toDateString() === today.toDateString();
            case 'yesterday':
                return date.toDateString() === yesterday.toDateString();
            case 'last 7 days':
                return date >= last7Days;
            case 'last 30 days':
                return date >= last30Days;
            case 'this month':
                return date >= thisMonthStart;
            case 'last month':
                return date >= lastMonthStart && date <= lastMonthEnd;
            default:
                return true;
        }
    };
    let sellerListdata = [];

    if (
        (Array.isArray(sellerList.users) && sellerList.users.length > 0) ||
        (typeof sellerList.users === 'object' && Object.keys(sellerList.users).length > 0)
    ) {
        const usersArray = Array.isArray(sellerList.users) ? sellerList.users : Object.values(sellerList.users);

        sellerListdata = usersArray
            .filter((item: any) => {
                const nameMatch = item.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                  item.last_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                  item.auth?.email?.toLowerCase().includes(searchTerm.toLowerCase());
                const statusMatch = statusFilter === 'All' || item.users_status.toLowerCase() === statusFilter.toLowerCase();
                const datesMatch = dateFilter === 'All' || isDateInRange(item.created_at, dateFilter);

                return nameMatch && statusMatch && datesMatch;
            })
            .map((item: any) => ({
                id:item.id,
                first_name: item.first_name || '',
                last_name: item.last_name || '',
                email: item.email || '',
                
                Phone_number: item.Phone_number,
                users_status: item.users_status,
                Profile_Image: item.Profile_Image,
                user_role: item.user_role,
                Address: item.Address,
                Telephone_number: item.Telephone_number,
                City: item.City,
                State: item.State,
                Zipcode:item.Zipcode,
                Country:item.Country,
                created_at:item.created_at
               
            }));
    }

    
    const fetchStatusanddateList = async () => {
        setLoader(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_dates_status`, 
                {
                   
                }
            );
            setstatusdate(response.data);
        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };
    
    useEffect(() => {
        fetchStatusanddateList();
    }, []);

console.log(statusdate, "statusdatestatusdatestatusdatestatusdatestatusdate")
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="List View" pageTitle="artist" />
                    <Row>
                        <Col xxl={3} md={6}>
                          
                        </Col>
                        <Col xxl={3} md={6}>
                            <Card className="bg-light border-0">
                               
                            </Card>
                        </Col>
                    </Row>

                 
                    <Row id="sellersList">
                    <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Row className="g-3">
                                <Col lg={3}>
                                    <Form.Control 
                                        type="text" 
                                        placeholder="Search..." 
                                        value={searchTerm}
                                        onChange={handleSearchChange} 
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Form.Select 
                                        value={statusFilter} 
                                        onChange={handleStatusFilterChange}
                                    >
                                        {/* <option value="All">All Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option> */}
                                           {statusdate?.status?.map((status:any, index:number) => (
                                            <option key={index} value={status}>{status}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col lg={3}>
                                    <Form.Select 
                                        value={dateFilter} 
                                        onChange={handleDateFilterChange}
                                    >

                                  

                                        {statusdate?.dates?.map((status:any, index:number) => (
                                            <option key={index} value={status}>{status}</option>
                                        ))}


                                        {/* <option value="Today">Today</option>
                                        <option value="Yesterday">Yesterday</option>
                                        <option value="Last 7 Days">Last 7 Days</option>
                                        <option value="Last 30 Days">Last 30 Days</option>
                                        <option value="This Month">This Month</option>
                                        <option value="Last Month">Last Month</option> */}
                                    </Form.Select>
                                </Col>
                                <Col className="col-lg-auto ms-auto">
                                    <div className="hstack gap-2">
                                        <Button 
                                            variant='primary' 
                                            className="add-btn" 
                                            onClick={() => setmodal_AddSellerModals(true)}
                                        >
                                            Add Admin
                                        </Button>
                                        <Dropdown>
                                            <Dropdown.Toggle className="btn-icon btn btn-soft-dark arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="ph-dots-three-outline"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu as="ul">
                                                <li><Link className="dropdown-item" to="#">Action</Link></li>
                                                <li><Link className="dropdown-item" to="#">Another action</Link></li>
                                                <li><Link className="dropdown-item" to="#">Something else here</Link></li>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                            <Modal className="fade addAdmin" show={modal_AddSellerModals} onHide={() => { tog_AddSellerModals(); }} centered>
                                <Modal.Header className="px-4 pt-4" closeButton>
                                    <h5 className="modal-title" id="exampleModalLabel">Add Admin</h5>
                                </Modal.Header>
                                
                                    <Form className="tablelist-form" onSubmit={validation.handleSubmit}>
                                        <Modal.Body className="p-4">
                                            <div id="alert-error-msg" className="d-none alert alert-danger py-2"></div>
                                            <input type="hidden" id="id-field" />
                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="first-name">Last Name<sup>*</sup></Form.Label>
                                                        <Form.Control type="text" name="last_name" id="first_name" placeholder="Enter First Name" 
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.last_name || ""}
                                                            isInvalid={
                                                                validation.touched.last_name && validation.errors.last_name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.last_name && validation.errors.last_name ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.last_name}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="last-name">First Name<sup>*</sup></Form.Label>
                                                        <Form.Control type="text" name="first_name" id="last-name" placeholder="Enter Last Name" 
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.first_name || ""}
                                                        isInvalid={
                                                            validation.touched.first_name && validation.errors.first_name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.first_name && validation.errors.first_name ? (
                                                        <Form.Control.Feedback type="invalid"><div>{validation.errors.first_name}</div></Form.Control.Feedback>
                                                    ) : null}
                                                        
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="email">Email<sup>*</sup> </Form.Label>
                                                        <Form.Control type="email" name="email" id="email" placeholder="Enter Email"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                            isInvalid={
                                                                validation.touched.email && validation.errors.email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.email && validation.errors.email ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.email}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        {/* <Form.Label htmlFor="file">Profile Image </Form.Label>
                                                        <Form.Control type="file" id="file" /> */}
                                                        <Form.Label htmlFor="file">Profile Image</Form.Label>
                            <Form.Control 
                                type="file" 
                                id="file" 
                                onChange={handleFileChange}
                            />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="password">Password<sup>*</sup></Form.Label>
                                                        <Form.Control type="password" name="password" id="password" placeholder="Enter Password" 
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.password || ""}
                                                            isInvalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.password}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="verify-password">Confirm Password<sup>*</sup></Form.Label>
                                                        <Form.Control type="password" name='confirm_password' id="verify-password" placeholder="Enter Password" 
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.confirm_password || ""}
                                                            isInvalid={
                                                                validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.confirm_password}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                {/* <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="Status">Status</Form.Label>
                                                        <Form.Control type="text" id="Status" placeholder="Enter Status" />
                                                    </div>
                                                </Col> */}
<Col md={6}>
<Form.Label htmlFor="users_status">Status</Form.Label>
    <Form.Control 
        as="select" 
        id="users_status" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.users_status || ""}
        isInvalid={
            validation.touched.users_status && validation.errors.users_status ? true : false
        }
    >
        <option value="">Select Status</option>
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
    </Form.Control>
    </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="phone-number">Phone number<sup>*</sup> </Form.Label>
                                                        <Form.Control type="number" name="Phone_number" id="phone-number" placeholder="Enter Phone number" 
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.Phone_number || ""}
                                                            isInvalid={
                                                                validation.touched.Phone_number && validation.errors.Phone_number ? true : false
                                                            }
                                                        />
                                                        {validation.touched.Phone_number && validation.errors.Phone_number ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.Phone_number}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="telephone-number">Telephone number </Form.Label>
                                                        <Form.Control type="number" name="Telephone_number" id="telephone-number" placeholder="Enter Telephone number" 
                                                         onChange={validation.handleChange}
                                                         onBlur={validation.handleBlur}
                                                         value={validation.values.Telephone_number || ""}
                                                         isInvalid={
                                                             validation.touched.Telephone_number && validation.errors.Telephone_number ? true : false
                                                         }/>

{validation.touched.Telephone_number && validation.errors.Telephone_number ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.Telephone_number}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="address">Address </Form.Label>
                                                        <Form.Control type="text" name="Address" id="address" placeholder="Enter Address" 
                                                          onChange={validation.handleChange}
                                                          onBlur={validation.handleBlur}
                                                          value={validation.values.Address || ""}
                                                          isInvalid={
                                                              validation.touched.Address && validation.errors.Address ? true : false
                                                          }/>


                                                          
{validation.touched.Address && validation.errors.Address ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.Address}</div></Form.Control.Feedback>
                                                        ) : null}


                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="city">City </Form.Label>
                                                        <Form.Control type="text" name="City" id="city" placeholder="Enter City"
                                                          onChange={validation.handleChange}
                                                          onBlur={validation.handleBlur}
                                                          value={validation.values.City || ""}
                                                          isInvalid={
                                                              validation.touched.City && validation.errors.City ? true : false
                                                          } />

                                                          
{validation.touched.City && validation.errors.City ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.City}</div></Form.Control.Feedback>
                                                        ) : null}

                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="state">State </Form.Label>
                                                        <Form.Control type="text" name="State" id="state" placeholder="Enter State"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.State || ""}
                                                        isInvalid={
                                                            validation.touched.State && validation.errors.State ? true : false
                                                        } />
                                                        {validation.touched.State && validation.errors.State ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.State}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="zipcode">Zipcode </Form.Label>
                                                        <Form.Control type="number" name="Zipcode" id="Zipcode" placeholder="Enter Zipcode"
                                                         onChange={validation.handleChange}
                                                         onBlur={validation.handleBlur}
                                                         value={validation.values.Zipcode || ""}
                                                         isInvalid={
                                                             validation.touched.Zipcode && validation.errors.Zipcode ? true : false
                                                         } />
                                                          {validation.touched.Zipcode && validation.errors.Zipcode ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.Zipcode}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="country">Country </Form.Label>
                                                        <Form.Control type="text" name="Country" id="country" placeholder="Enter Country" 
                                                         onChange={validation.handleChange}
                                                         onBlur={validation.handleBlur}
                                                         value={validation.values.Country || ""}
                                                         isInvalid={
                                                             validation.touched.Country && validation.errors.Country ? true : false
                                                         }/>

{validation.touched.Zipcode && validation.errors.Country ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.Country}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Modal.Body>
                                        <div className="modal-footer">
                                            <div className="hstack gap-2 justify-content-end">
                                                {/* <Button className="btn-ghost-danger" onClick={() => { tog_AddSellerModals(); }}>Close</Button> */}
                                                <Button variant='success' type='submit' id="add-btn">Add Adminddd</Button>
                                            </div>
                                        </div>
                                    </Form>
                                
                            </Modal>

                            <Card>
                                <Card.Body className='p-0'>
                                    {/* <div className="table-responsive table-card mb-1"> */}
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                        <ListViewTable sellerListss={sellerListdata} fetchSellerListss={fetchSellerList} />
                                        
                                      
                                        </table>
                                        <div className="noresult" style={{ display: "none" }}>
                                            <div className="text-center py-4">
                                                <div className="avatar-md mx-auto mb-4">
                                                    <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-24">
                                                        <i className="bi bi-search"></i>
                                                    </div>
                                                </div>
                                                <h5 className="mt-2">Sorry! No Result Found</h5>
                                                <p className="text-muted mb-0">We've searched more than 150+ seller We did not find any seller for you search.</p>
                                            </div>
                                        </div>
                                    {/* </div> */}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* { <ListViewTable termsDataa={validation.values} /> } */}
        </React.Fragment>
    );
}






export default SellersListView;