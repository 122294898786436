// import React, { useState, useMemo } from 'react';
// import TableContainer from "Common/TableContainer";
// import { useNavigate } from 'react-router-dom';
// import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
// import axios from 'axios';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';

// interface ListViewTableProps {
//     allrolelist: any[];
// }

// const ListViewTable: React.FC<ListViewTableProps> = ({ allrolelist }) => {
//     const [loader, setLoader] = useState<boolean>(false);
//     const [modal_AddSellerModals, setModalAddSellerModals] = useState<boolean>(false);
//     const [selectedItem, setSelectedItem] = useState<any>(null);

//     const navigate = useNavigate();

//     const tokenString = localStorage.getItem('authUser');
//     let token = '';

//     if (tokenString) {
//         const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
//         token = tokenObject.token;
//     }

//     const handleEditClick = (item: any) => {
//         setSelectedItem(item);
//         validation.setValues({
//             id: item.id,
//             allies: item.Alias || '', // Ensure default value is set if Alias is undefined
//             title: item.Title || '', // Ensure default value is set if Title is undefined
//         });
//         setModalAddSellerModals(true);
//     };

//     const handleDeleteClick = async (item: any) => {
//         try {
//             setLoader(true);
//             await axios.get(
//                 `${process.env.REACT_APP_API_BASEURL}/auth/delete_roles`,
//                 {
//                     headers: {
//                         'Content-Type': 'application/json',
//                         'Authorization': `Bearer ${token}`,
//                     },
//                     params: {
//                         id: item.id,
//                     },
//                 }
//             );
//             // Refresh the list or handle state update after deletion
//         } catch (error) {
//             console.error(error);
//         } finally {
//             setLoader(false);
//         }
//     };

//     const validation = useFormik({
//         initialValues: {
//             id: '',
//             allies: '',
//             title: '',
//         },
//         validationSchema: Yup.object({
//             allies: Yup.string().required('Allies is required'),
//             title: Yup.string().required('Title is required'),
//         }),
//         onSubmit: async (values) => {
//             try {
//                 setLoader(true);
//                 await axios.post(
//                     `${process.env.REACT_APP_API_BASEURL}/auth/edit_roles`,
//                     {
//                         id: values.id,
//                         title: values.title,
//                         allies: values.allies,
//                     },
//                     {
//                         headers: {
//                             'Content-Type': 'application/json',
//                             'Authorization': `Bearer ${token}`,
//                         },
//                     }
//                 );
//                 setModalAddSellerModals(false);
//                 validation.resetForm();
//                 // Optionally, refresh the list or handle state update after editing
//             } catch (error) {
//                 console.error(error);
//             } finally {
//                 setLoader(false);
//             }
//         },
//     });

//     const columns = useMemo(
//         () => [
//             {
//                 Header: (
//                     <div className="form-check">
//                         <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
//                     </div>
//                 ),
//                 Cell: (cellProps: any) => (
//                     <div className="form-check">
//                         <input className="form-check-input" type="checkbox" name="chk_child" defaultValue="option1" />
//                     </div>
//                 ),
//                 id: '#',
//             },
//             {
//                 Header: "Title",
//                 accessor: "Title",
//                 disableFilters: true,
//                 filterable: true,
//             },
//             {
//                 Header: "Alias",
//                 accessor: "Alias",
//                 disableFilters: true,
//                 filterable: true,
//             },
//             {
//                 Header: "Created At",
//                 accessor: "created_at",
//                 disableFilters: true,
//                 filterable: true,
//             },
//             {
//                 Header: "Action",
//                 disableFilters: true,
//                 filterable: true,
//                 Cell: (cellProps: any) => {
//                     const rowData = cellProps.row ? cellProps.row.original : {};
//                     return (
//                         <div className="d-flex gap-2">
//                             <div className="edit">
//                                 <Button
//                                     onClick={() => handleEditClick(rowData)}
//                                     className="btn btn-sm btn-soft-info"
//                                 >
//                                     Edit
//                                 </Button>
//                             </div>
//                             <div className="remove">
//                                 <Button
//                                     variant='soft-danger'
//                                     size="sm"
//                                     className="remove-item-btn"
//                                     onClick={() => handleDeleteClick(rowData)}
//                                 >
//                                     Remove
//                                 </Button>
//                             </div>
//                         </div>
//                     );
//                 },
//             },
//         ],
//         [navigate]
//     );

//     return (
//         <React.Fragment>
//             <TableContainer
//                 columns={columns}
//                 sellerListss={allrolelist}
//                 iscustomPageSize={false}
//                 isBordered={false}
//                 customPageSize={10}
//                 className="custom-header-css table align-middle table-nowrap"
//                 tableClass="table-centered align-middle table-nowrap mb-0"
//                 theadClass="text-muted table-light"
//                 SearchPlaceholder='Search Products...'
//             />
//             <Modal className="fade addAdmin" show={modal_AddSellerModals} onHide={() => setModalAddSellerModals(false)} centered>
//                 <Modal.Header className="px-4 pt-4" closeButton>
//                     <h5 className="modal-title" id="exampleModalLabel">Edit Role</h5>
//                 </Modal.Header>
//                 <Form className="tablelist-form" onSubmit={validation.handleSubmit}>
//                     <Modal.Body className="p-4">
//                         <div id="alert-error-msg" className="d-none alert alert-danger py-2"></div>
//                         <Row>
//                             <Col md={6}>
//                                 <div className="mb-3">
//                                     <Form.Label htmlFor="allies">Allies</Form.Label>
//                                     <Form.Control
//                                         type="text"
//                                         name="allies"
//                                         id="allies"
//                                         placeholder="Enter allies"
//                                         onChange={validation.handleChange}
//                                         onBlur={validation.handleBlur}
//                                         value={validation.values.allies || ""} // Set value to previous alias
//                                         isInvalid={validation.touched.allies && validation.errors.allies ? true : false}
//                                     />
//                                     {validation.touched.allies && validation.errors.allies ? (
//                                         <Form.Control.Feedback type="invalid">
//                                             <div>{validation.errors.allies}</div>
//                                         </Form.Control.Feedback>
//                                     ) : null}
//                                 </div>
//                             </Col>
//                             <Col md={6}>
//                                 <div className="mb-3">
//                                     <Form.Label htmlFor="title">Title</Form.Label>
//                                     <Form.Control
//                                         type="text"
//                                         name="title"
//                                         id="title"
//                                         placeholder="Enter title"
//                                         onChange={validation.handleChange}
//                                         onBlur={validation.handleBlur}
//                                         value={validation.values.title || ""} // Set value to previous title
//                                         isInvalid={validation.touched.title && validation.errors.title ? true : false}
//                                     />
//                                     {validation.touched.title && validation.errors.title ? (
//                                         <Form.Control.Feedback type="invalid">
//                                             <div>{validation.errors.title}</div>
//                                         </Form.Control.Feedback>
//                                     ) : null}
//                                 </div>
//                             </Col>
//                         </Row>
//                     </Modal.Body>
//                     <div className="modal-footer">
//                         <div className="hstack gap-2 justify-content-end">
//                             <Button className="btn-ghost-danger" onClick={() => setModalAddSellerModals(false)}>
//                                 <i className="ri-close-line align-bottom"></i> Close
//                             </Button>
//                             <Button type="submit" variant='success' id="add-btn">Update Role</Button>
//                         </div>
//                     </div>
//                 </Form>
//             </Modal>
//         </React.Fragment>
//     );
// };

// export default ListViewTable;




import React, { useState, useMemo } from 'react';
import TableContainer from "Common/TableContainer";
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';

interface ListViewTableProps {
    allrolelist: any[];
}

const ListViewTable: React.FC<ListViewTableProps> = ({ allrolelist }) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [modal_AddSellerModals, setModalAddSellerModals] = useState<boolean>(false);
    const [modal_DeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const navigate = useNavigate();

    const tokenString = localStorage.getItem('authUser');
    let token = '';

    if (tokenString) {
        const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
        token = tokenObject.token;
    }

    const handleEditClick = (item: any) => {
        setSelectedItem(item);
        validation.setValues({
            id: item.id,
            allies: item.Alias || '', // Ensure default value is set if Alias is undefined
            title: item.Title || '', // Ensure default value is set if Title is undefined
        });
        setModalAddSellerModals(true);
    };

    const handleDeleteClick = (item: any) => {
        setSelectedItem(item);
        setModalDeleteConfirm(true);
    };

    const confirmDelete = async () => {
        try {
            if (selectedItem) {
                setLoader(true);
                await axios.get(
                    `${process.env.REACT_APP_API_BASEURL}/auth/delete_roles`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        params: {
                            id: selectedItem.id,
                        },
                    }
                );
                
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoader(false);
            setModalDeleteConfirm(false);
        }
    };

    const validation = useFormik({
        initialValues: {
            id: '',
            allies: '',
            title: '',
        },
        validationSchema: Yup.object({
            allies: Yup.string().required('Allies is required'),
            title: Yup.string().required('Title is required'),
        }),
        onSubmit: async (values) => {
            try {
                setLoader(true);
                await axios.post(
                    `${process.env.REACT_APP_API_BASEURL}/auth/edit_roles`,
                    {
                        id: values.id,
                        title: values.title,
                        allies: values.allies,
                    }, 
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
                setModalAddSellerModals(false);
                validation.resetForm();
                // Optionally, refresh the list or handle state update after editing
            } catch (error) {
                console.error(error);
            } finally {
                setLoader(false);
            }
        },
    });

    const columns = useMemo(
        () => [
            {
                Header: (
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                    </div>
                ),
                Cell: (cellProps: any) => (
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="chk_child" defaultValue="option1" />
                    </div>
                ),
                id: '#',
            },
            {
                Header: "Title",
                accessor: "Title",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Alias",
                accessor: "Alias",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Created At",
                accessor: "created_at",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Action",
                disableFilters: true,
                filterable: true,
                Cell: (cellProps: any) => {
                    const rowData = cellProps.row ? cellProps.row.original : {};
                    return (
                        <div className="d-flex gap-2">
                            <div className="edit">
                                <Button
                                    onClick={() => handleEditClick(rowData)}
                                    className="btn btn-sm btn-soft-info"
                                >
                                    Edit
                                </Button>
                            </div>
                            <div className="remove">
                                <Button
                                    variant='soft-danger'
                                    size="sm"
                                    className="remove-item-btn"
                                    onClick={() => handleDeleteClick(rowData)}
                                >
                                    Remove
                                </Button>
                            </div>
                        </div>
                    );
                },
            },
        ],
        [navigate]
    );

    return (
        <React.Fragment>
            <TableContainer
                columns={columns}
                sellerListss={allrolelist}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                className="custom-header-css table align-middle table-nowrap"
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                SearchPlaceholder='Search Products...'
            />
            <Modal className="fade addAdmin" show={modal_AddSellerModals} onHide={() => setModalAddSellerModals(false)} centered>
                <Modal.Header className="px-4 pt-4" closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">Edit Role</h5>
                </Modal.Header>
                <Form className="tablelist-form" onSubmit={validation.handleSubmit}>
                    <Modal.Body className="p-4">
                        <div id="alert-error-msg" className="d-none alert alert-danger py-2"></div>
                        <Row>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Form.Label htmlFor="allies">Allies</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="allies"
                                        id="allies"
                                        placeholder="Enter allies"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.allies || ""} // Set value to previous alias
                                        isInvalid={validation.touched.allies && validation.errors.allies ? true : false}
                                    />
                                    {validation.touched.allies && validation.errors.allies ? (
                                        <Form.Control.Feedback type="invalid">
                                            <div>{validation.errors.allies}</div>
                                        </Form.Control.Feedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Form.Label htmlFor="title">Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        id="title"
                                        placeholder="Enter title"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.title || ""} // Set value to previous title
                                        isInvalid={validation.touched.title && validation.errors.title ? true : false}
                                    />
                                    {validation.touched.title && validation.errors.title ? (
                                        <Form.Control.Feedback type="invalid">
                                            <div>{validation.errors.title}</div>
                                        </Form.Control.Feedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <div className="modal-footer">
                        <div className="hstack gap-2 justify-content-end">
                            <Button className="btn-ghost-danger" onClick={() => setModalAddSellerModals(false)}>
                                <i className="ri-close-line align-bottom"></i> Close
                            </Button>
                            <Button type="submit" variant='success' id="add-btn">Update Role</Button>
                        </div>
                    </div>
                </Form>
            </Modal>

            <Modal show={modal_DeleteConfirm} onHide={() => setModalDeleteConfirm(false)} centered>
    <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <p>Are you sure you want to delete this item?</p>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalDeleteConfirm(false)}>
            Cancel
        </Button>
        <Button variant="danger" onClick={confirmDelete}>
            Delete
        </Button>
    </Modal.Footer>
</Modal>

        </React.Fragment>
    );
};

export default ListViewTable;

