import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useState } from 'react';
import { Card, Col, Form, Row, Accordion, Button } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';

const TermsInstruct: React.FC = () => {
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
    const [accordionCount, setAccordionCount] = useState(1);

    const increaseAccordionCount = () => {
        setAccordionCount(accordionCount + 1);
    };

    const decrementAccordionCount = () => {
        setAccordionCount(accordionCount - 1);
    };

    const handleAcceptedFiles = (files: any) => {
        files.map((file: any) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setSelectedFiles(files);
    };

    const formatBytes = (bytes: number, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    return (
        <React.Fragment>
            <Row>
                <Col lg={4}>
                    <h5 className="fs-16">Glimpse Of Author</h5>
                    <p className="text-muted mb-lg-0">
                        Security is about the safeguarding of data, whereas privacy is about the safeguarding of user identity. The specific differences, however, are more complex, and there can certainly be areas of overlap between the two.
                    </p>
                </Col>
                <Col lg={8}>
                    <Card>
                        <Card.Body>
                            <div className="mb-3 d-flex align-items-center">
                                <tr>
                                    <td>
                                        <Button variant="danger" onClick={decrementAccordionCount} disabled={accordionCount <= 1}>-</Button>
                                    </td>
                                    <td>
                                        <p>{accordionCount}</p>
                                    </td>
                                    <td>
                                        <Button variant="primary" onClick={increaseAccordionCount}>+</Button>
                                    </td>
                                </tr>

                            </div>
                            <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
                                {Array.from({ length: accordionCount }, (_, index) => (
                                    <Accordion.Item key={index} eventKey={`${index}`}>
                                        <Accordion.Header>Form {index + 1}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row className="g-3">
                                                <Col lg={6}>
                                                    <div>
                                                        <Form.Label htmlFor="Title">Title<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" id="Title" placeholder="Title" defaultValue="" />
                                                    </div>
                                                    <div className='mt-2'>
                                                        <Form.Label htmlFor="choices-publish-visibility-input">Image Alignment</Form.Label>
                                                        <select className="form-select" id="choices-publish-visibility-input" data-choices data-choices-search-false>
                                                            <option defaultValue="Public">Public</option>
                                                            <option value="Hidden">Hidden</option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div>
                                                        <Dropzone
                                                            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
                                                        >
                                                            {({ getRootProps, getInputProps }) => (
                                                                <div style={{ minHeight: "161px" }} className="dropzone2 dz-clickable text-center">
                                                                    <div className="dz-message needsclick" {...getRootProps()}>
                                                                        <div className="mb-3">
                                                                            <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                                                        </div>
                                                                        <h5>Drop files here or click to upload.</h5>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Dropzone>
                                                        <div className="list-unstyled mb-0" id="file-previews">
                                                            {selectedFiles.map((file, i) => (
                                                                <Card className="mt-1 mb-0 shadow-none border dz-preview dz-processing dz-image-preview dz-success dz-image dz-complete" key={i + "-file"}>
                                                                    <div className="p-2">
                                                                        <Row className="align-items-center">
                                                                            <Col className="col-auto">
                                                                                <img className="avatar-sm rounded bg-light" alt={file.name} src={file.preview} />
                                                                            </Col>
                                                                            <Col>
                                                                                <Link to="#" className="text-muted font-weight-bold">
                                                                                    {file.name}
                                                                                </Link>
                                                                                <p className="mb-0">
                                                                                    <strong>{file.formattedSize}</strong>
                                                                                </p>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Card>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Form.Label>Description</Form.Label>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data="<p>Description goes here...</p>"
                                                            onReady={(editor: any) => { /* Editor is ready */ }}
                                                            onChange={(event: any, editor: any) => {
                                                                const data = editor.getData();
                                                                console.log(data);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default TermsInstruct;
