import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Container, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import axios from 'axios';
import * as Yup from 'yup';
// import { Formik } from 'formik';
import { useFormik } from "formik";
import { registerUser, apiError, resetRegisterFlag } from "slices/thunk";
import { useSelector, useDispatch } from "react-redux";

import avatar2 from "../../../assets/images/users/avatar-2.jpg"
import ListViewTable from './listViewTable';
import { v4 as uuidv4 } from 'uuid';
import { useDebounce } from 'use-debounce';
const CustomersListView = () => {

   

    
    const dispatch = useDispatch<any>();

   
    const [loader, setLoader] = useState<boolean>(false);
    const [customersList, setcustomersList] = useState<any>([]);
    const [allstatus, setStatus] = useState<any>([]);
    const [alldates, setDates] = useState<any>([]);
    const [modalAddSeller, setModalAddCustomers] = useState<boolean>(false);
    const [searchdates, setSearchdates] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [statussearchTerm, setstatusSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');
    const [dateFilter, setDateFilter] = useState('All');
    
    const [statusdate, setstatusdate] =useState<any>([]);;
  
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500); 

 
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            last_name: '',
            first_name: '',
            password: '',
            confirm_password: '',
            Phone_number: '',
            Profile_Image: null,
            Telephone_number:'',
            Address:'',
            City:'',
            State:'',
            Zipcode:'',
            Country:'',
            users_status:''


        },
        validationSchema: Yup.object({
            // email: Yup.string().required("Please Enter Your Email"),
            // first_name: Yup.string().required("Please Enter Your First Name"),
            // last_name: Yup.string().required("Please Enter Your Last Name"),
            // password: Yup.string().required("Please Enter Your Password")
            //     .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"),
            // confirm_password: Yup.string().oneOf([Yup.ref('password'), undefined], 'Password must match')
            //     .required("Please Enter Your Password"),
            // Phone_number: Yup.string().required("Please Enter Your Mobile Number")
            //     .matches(/^\d{10}$/, "Phone Number must be 10 digits"),
        }),
        onSubmit: async (values) => {
            console.log( values, "55555")
            setLoader(true);
            try {
                const formData = new FormData();
                Object.keys(values).forEach(key => {
                    if (key === 'Profile_Image' && values[key]) {
                        formData.append(key, values[key]);
                    } else {
                        formData.append(key, (values as any)[key]);
                    }
                });

                const tokenString = localStorage.getItem('authUser');
                let token = '';

                if (tokenString) {
                    const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
                    token = tokenObject.token;
                }

                await axios.post(
                    `${process.env.REACT_APP_API_BASEURL}/auth/addcustomer`,
                    formData,

                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
                setModalAddCustomers(false);
                validation.resetForm();
            } catch (error) {
                console.error(error);
            } finally {
                setLoader(false);
            }
        },
    });
console.log(FormData, "formDataformData")
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            validation.setFieldValue('Profile_Image', event.target.files[0]);
        }
    };

console.log(validation, "validationvalidationvalidationvalidationvalidationvalidation" )
    document.title = "List View - Sellers | Artflute + Admin React Template";

    const [modal_AddCustomersModals, setmodal_AddCustomersModals] = useState<boolean>(false);
    function tog_AddCustomersModals() {
        setmodal_AddCustomersModals(!modal_AddCustomersModals);
    }


    const getToken = () => {
        const tokenString = localStorage.getItem('authUser');
        if (tokenString) {
            const tokenObject = JSON.parse(tokenString);
            return tokenObject.token || '';
        }
        return '';
    };

    

    const fetchcustomersList = async () => {
        setLoader(true);
        try {
            const token = getToken();
    
            const queryParams = new URLSearchParams({
                search: debouncedSearchTerm, 
                status: statusFilter === 'All' ? '' : statusFilter.toLowerCase(),
                date: dateFilter === 'All' ? '' : dateFilter.toLowerCase()
            }).toString();
    
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_customer_details?${queryParams}`, 
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            setcustomersList(response.data);
        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };
    
    useEffect(() => {
        fetchcustomersList();
    }, [debouncedSearchTerm, statusFilter, dateFilter]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleStatusFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setStatusFilter(event.target.value);
    };

    const handleDateFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDateFilter(event.target.value);
    };

   

    const isDateInRange = (dateString: string, filter: string) => {
        const date = new Date(dateString);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        const last7Days = new Date(today);
        last7Days.setDate(today.getDate() - 7);
        const last30Days = new Date(today);
        last30Days.setDate(today.getDate() - 30);
        const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

        switch (filter) {
            case 'today':
                return date.toDateString() === today.toDateString();
            case 'yesterday':
                return date.toDateString() === yesterday.toDateString();
            case 'last 7 days':
                return date >= last7Days;
            case 'last 30 days':
                return date >= last30Days;
            case 'this month':
                return date >= thisMonthStart;
            case 'last month':
                return date >= lastMonthStart && date <= lastMonthEnd;
            default:
                return true;
        }
    };

    let customerslist = [];

    if (
        (Array.isArray(customersList.users) && customersList.users.length > 0) ||
        (typeof customersList.users === 'object' && Object.keys(customersList.users).length > 0)
    ) {
        const usersArray = Array.isArray(customersList.users) ? customersList.users : Object.values(customersList.users);

        customerslist = usersArray
            .filter((item: any) => {
                const nameMatch = item.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                  item.last_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                  item.auth?.email?.toLowerCase().includes(searchTerm.toLowerCase());
                const statusMatch = statusFilter === 'All' || item.users_status.toLowerCase() === statusFilter.toLowerCase();
                const datesMatch = dateFilter === 'All' || isDateInRange(item.created_at, dateFilter);

                return nameMatch && statusMatch && datesMatch;
            })
            .map((item: any) => ({
                id:item.id,
                first_name: item.first_name || '',
                last_name: item.last_name || '',
                email: item.email || '',
                
                Phone_number: item.Phone_number,
                users_status: item.users_status,
                Profile_Image: item.Profile_Image,
                user_role: item.user_role,
                Address: item.Address,
                Telephone_number: item.Telephone_number,
                City: item.City,
                State: item.State,
                Zipcode:item.Zipcode,
                Country:item.Country,
                created_at:item.created_at

            }));
    }

    
    const fetchStatusanddateList = async () => {
        setLoader(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_dates_status`, 
                {
                   
                }
            );
            setstatusdate(response.data);
        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };
    
    useEffect(() => {
        fetchStatusanddateList();
    }, []);
    console.log(statusdate, "statusdatestatusdatestatusdatestatusdate")
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="List View" pageTitle="artist" />
                    <Row>
                        <Col xxl={3} md={6}>
                            {/* <Card className="card-height-100 bg-warning-subtle border-0 overflow-hidden">
                                <div className="position-absolute end-0 start-0 top-0 z-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                                        // xmlns:xlink="http://www.w3.org/1999/xlink" 
                                        width="400" height="250" preserveAspectRatio="none" viewBox="0 0 400 250">
                                        <g mask="url(&quot;#SvgjsMask1530&quot;)" fill="none">
                                            <path d="M209 112L130 191" strokeWidth="10" stroke="url(#SvgjsLinearGradient1531)" strokeLinecap="round" className="BottomLeft"></path>
                                            <path d="M324 10L149 185" strokeWidth="8" stroke="url(#SvgjsLinearGradient1532)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M333 35L508 -140" strokeWidth="10" stroke="url(#SvgjsLinearGradient1532)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M282 58L131 209" strokeWidth="10" stroke="url(#SvgjsLinearGradient1531)" strokeLinecap="round" className="BottomLeft"></path>
                                            <path d="M290 16L410 -104" strokeWidth="6" stroke="url(#SvgjsLinearGradient1532)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M216 186L328 74" strokeWidth="6" stroke="url(#SvgjsLinearGradient1531)" strokeLinecap="round" className="BottomLeft"></path>
                                            <path d="M255 53L176 132" strokeWidth="10" stroke="url(#SvgjsLinearGradient1531)" strokeLinecap="round" className="BottomLeft"></path>
                                            <path d="M339 191L519 11" strokeWidth="8" stroke="url(#SvgjsLinearGradient1531)" strokeLinecap="round" className="BottomLeft"></path>
                                            <path d="M95 151L185 61" strokeWidth="6" stroke="url(#SvgjsLinearGradient1532)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M249 16L342 -77" strokeWidth="6" stroke="url(#SvgjsLinearGradient1532)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M129 230L286 73" strokeWidth="10" stroke="url(#SvgjsLinearGradient1531)" strokeLinecap="round" className="BottomLeft"></path>
                                            <path d="M80 216L3 293" strokeWidth="6" stroke="url(#SvgjsLinearGradient1531)" strokeLinecap="round" className="BottomLeft"></path>
                                        </g>
                                        <defs>
                                            <mask id="SvgjsMask1530">
                                                <rect width="400" height="250" fill="#ffffff"></rect>
                                            </mask>
                                            <linearGradient x1="100%" y1="0%" x2="0%" y2="100%" id="SvgjsLinearGradient1531">
                                                <stop stopColor="rgba(var(--tb-warning-rgb), 0)" offset="0"></stop>
                                                <stop stopColor="rgba(var(--tb-warning-rgb), 0.2)" offset="1"></stop>
                                            </linearGradient>
                                            <linearGradient x1="0%" y1="100%" x2="100%" y2="0%" id="SvgjsLinearGradient1532">
                                                <stop stopColor="rgba(var(--tb-warning-rgb), 0)" offset="0"></stop>
                                                <stop stopColor="rgba(var(--tb-warning-rgb), 0.2)" offset="1"></stop>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <Card.Body className="p-4 z-1 position-relative">
                                    <h4 className="fs-22 fw-semibold mb-3">
                                        <CountUp start={0} end={559.25} duration={3} decimals={2} suffix="k" />
                                    </h4>
                                    <p className="mb-0 fw-medium text-uppercase fs-14">Total Sellers</p>
                                </Card.Body>
                            </Card> */}
                        </Col>
                        <Col xxl={3} md={6}>
                            {/* <Card className="card-height-100 bg-success-subtle border-0 overflow-hidden">
                                <div className="position-absolute end-0 start-0 top-0 z-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                                        // xmlns:xlink="http://www.w3.org/1999/xlink" 
                                        width="400" height="250" preserveAspectRatio="none" viewBox="0 0 400 250">
                                        <g mask="url(&quot;#SvgjsMask1608&quot;)" fill="none">
                                            <path d="M390 87L269 208" strokeWidth="10" stroke="url(#SvgjsLinearGradient1609)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M358 175L273 260" strokeWidth="8" stroke="url(#SvgjsLinearGradient1610)" strokeLinecap="round" className="BottomLeft"></path>
                                            <path d="M319 84L189 214" strokeWidth="10" stroke="url(#SvgjsLinearGradient1609)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M327 218L216 329" strokeWidth="8" stroke="url(#SvgjsLinearGradient1610)" strokeLinecap="round" className="BottomLeft"></path>
                                            <path d="M126 188L8 306" strokeWidth="8" stroke="url(#SvgjsLinearGradient1610)" strokeLinecap="round" className="BottomLeft"></path>
                                            <path d="M220 241L155 306" strokeWidth="10" stroke="url(#SvgjsLinearGradient1610)" strokeLinecap="round" className="BottomLeft"></path>
                                            <path d="M361 92L427 26" strokeWidth="6" stroke="url(#SvgjsLinearGradient1609)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M391 188L275 304" strokeWidth="8" stroke="url(#SvgjsLinearGradient1609)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M178 74L248 4" strokeWidth="10" stroke="url(#SvgjsLinearGradient1610)" strokeLinecap="round" className="BottomLeft"></path>
                                            <path d="M84 52L-56 192" strokeWidth="6" stroke="url(#SvgjsLinearGradient1610)" strokeLinecap="round" className="BottomLeft"></path>
                                            <path d="M183 111L247 47" strokeWidth="10" stroke="url(#SvgjsLinearGradient1610)" strokeLinecap="round" className="BottomLeft"></path>
                                            <path d="M46 8L209 -155" strokeWidth="6" stroke="url(#SvgjsLinearGradient1609)" strokeLinecap="round" className="TopRight"></path>
                                        </g>
                                        <defs>
                                            <mask id="SvgjsMask1608">
                                                <rect width="400" height="250" fill="#ffffff"></rect>
                                            </mask>
                                            <linearGradient x1="0%" y1="100%" x2="100%" y2="0%" id="SvgjsLinearGradient1609">
                                                <stop stopColor="rgba(var(--tb-success-rgb), 0)" offset="0"></stop>
                                                <stop stopColor="rgba(var(--tb-success-rgb), 0.2)" offset="1"></stop>
                                            </linearGradient>
                                            <linearGradient x1="100%" y1="0%" x2="0%" y2="100%" id="SvgjsLinearGradient1610">
                                                <stop stopColor="rgba(var(--tb-success-rgb), 0)" offset="0"></stop>
                                                <stop stopColor="rgba(var(--tb-success-rgb), 0.2)" offset="1"></stop>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <Card.Body className="p-4 z-1 position-relative">
                                    <h4 className="fs-22 fw-semibold mb-3">
                                        <CountUp start={0} end={559.25} duration={3} decimals={2} suffix="k" />
                                    </h4>
                                    <p className="mb-0 fw-medium text-uppercase fs-14">Wholesalers</p>
                                </Card.Body>
                            </Card> */}
                        </Col>
                        <Col xxl={3} md={6}>
                            {/* <Card className="card-height-100 bg-info-subtle border-0 overflow-hidden">
                                <div className="position-absolute end-0 start-0 top-0 z-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                                        // xmlns:xlink="http://www.w3.org/1999/xlink" 
                                        width="400" height="250" preserveAspectRatio="none" viewBox="0 0 400 250">
                                        <g mask="url(&quot;#SvgjsMask1551&quot;)" fill="none">
                                            <path d="M306 65L446 -75" strokeWidth="8" stroke="url(#SvgjsLinearGradient1552)" strokeLinecap="round" className="BottomLeft"></path>
                                            <path d="M399 2L315 86" strokeWidth="10" stroke="url(#SvgjsLinearGradient1553)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M83 77L256 -96" strokeWidth="6" stroke="url(#SvgjsLinearGradient1553)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M281 212L460 33" strokeWidth="6" stroke="url(#SvgjsLinearGradient1553)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M257 62L76 243" strokeWidth="6" stroke="url(#SvgjsLinearGradient1553)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M305 123L214 214" strokeWidth="6" stroke="url(#SvgjsLinearGradient1552)" strokeLinecap="round" className="BottomLeft"></path>
                                            <path d="M327 222L440 109" strokeWidth="6" stroke="url(#SvgjsLinearGradient1552)" strokeLinecap="round" className="BottomLeft"></path>
                                            <path d="M287 109L362 34" strokeWidth="10" stroke="url(#SvgjsLinearGradient1553)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M259 194L332 121" strokeWidth="8" stroke="url(#SvgjsLinearGradient1553)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M376 186L240 322" strokeWidth="8" stroke="url(#SvgjsLinearGradient1553)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M308 153L123 338" strokeWidth="6" stroke="url(#SvgjsLinearGradient1553)" strokeLinecap="round" className="TopRight"></path>
                                            <path d="M218 62L285 -5" strokeWidth="8" stroke="url(#SvgjsLinearGradient1552)" strokeLinecap="round" className="BottomLeft"></path>
                                        </g>
                                        <defs>
                                            <mask id="SvgjsMask1551">
                                                <rect width="400" height="250" fill="#ffffff"></rect>
                                            </mask>
                                            <linearGradient x1="100%" y1="0%" x2="0%" y2="100%" id="SvgjsLinearGradient1552">
                                                <stop stopColor="rgba(var(--tb-info-rgb), 0)" offset="0"></stop>
                                                <stop stopColor="rgba(var(--tb-info-rgb), 0.2)" offset="1"></stop>
                                            </linearGradient>
                                            <linearGradient x1="0%" y1="100%" x2="100%" y2="0%" id="SvgjsLinearGradient1553">
                                                <stop stopColor="rgba(var(--tb-info-rgb), 0)" offset="0"></stop>
                                                <stop stopColor="rgba(var(--tb-info-rgb), 0.2)" offset="1"></stop>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <Card.Body className="p-4 z-1 position-relative">
                                    <h4 className="fs-22 fw-semibold mb-3">
                                        <CountUp start={0} end={559.25} duration={3} decimals={2} suffix="k" />
                                    </h4>
                                    <p className="mb-0 fw-medium text-uppercase fs-14">Retail Seller</p>
                                </Card.Body>
                            </Card> */}
                        </Col>
                        <Col xxl={3} md={6}>
                            <Card className="bg-light border-0">
                                {/* <Card.Body className="p-3">
                                    <div className="p-3 bg-white rounded">
                                        <div className="d-flex align-items-center gap-2">
                                            <div className="flex-shrink-0">
                                                <img src={avatar2} alt="" className="avatar-sm rounded-circle" />
                                            </div>
                                            <div className="flex-grow-1">
                                                <Link to="#!"><h6 className="fs-16"><span className="text-success">#1</span> Amanda Harvey</h6></Link>
                                                <p className="text-muted mb-0">To reach if you need to sell 200+ orders.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body> */}
                            </Card>
                        </Col>
                    </Row>

                    <Row id="sellersList">
                    <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Row className="g-3">
                                <Col lg={3}>
                                    <Form.Control 
                                        type="text" 
                                        placeholder="Search..." 
                                        value={searchTerm}
                                        onChange={handleSearchChange} 
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Form.Select 
                                        value={statusFilter} 
                                        onChange={handleStatusFilterChange}
                                    >
                                        {/* <option value="All">All Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option> */}
                                           {statusdate?.status?.map((status:any, index:number) => (
                                            <option key={index} value={status}>{status}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col lg={3}>
                                    <Form.Select 
                                        value={dateFilter} 
                                        onChange={handleDateFilterChange}
                                    >

                                  

                                        {statusdate?.dates?.map((status:any, index:number) => (
                                            <option key={index} value={status}>{status}</option>
                                        ))}


                                        {/* <option value="Today">Today</option>
                                        <option value="Yesterday">Yesterday</option>
                                        <option value="Last 7 Days">Last 7 Days</option>
                                        <option value="Last 30 Days">Last 30 Days</option>
                                        <option value="This Month">This Month</option>
                                        <option value="Last Month">Last Month</option> */}
                                    </Form.Select>
                                </Col>
                                <Col className="col-lg-auto ms-auto">
                                    <div className="hstack gap-2">
                                        <Button 
                                            variant='primary' 
                                            className="add-btn" 
                                            onClick={() => setmodal_AddCustomersModals(true)}
                                        >
                                            Add Customers
                                        </Button>
                                        <Dropdown>
                                            <Dropdown.Toggle className="btn-icon btn btn-soft-dark arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="ph-dots-three-outline"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu as="ul">
                                                <li><Link className="dropdown-item" to="#">Action</Link></li>
                                                <li><Link className="dropdown-item" to="#">Another action</Link></li>
                                                <li><Link className="dropdown-item" to="#">Something else here</Link></li>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                            <Modal className="fade addAdmin" show={modal_AddCustomersModals} onHide={() => { tog_AddCustomersModals(); }} centered>
                                <Modal.Header className="px-4 pt-4" closeButton>
                                    <h5 className="modal-title" id="exampleModalLabel">Add Customers</h5>
                                </Modal.Header>
                                
                                    <Form className="tablelist-form" onSubmit={validation.handleSubmit}>
                                        <Modal.Body className="p-4">
                                            <div id="alert-error-msg" className="d-none alert alert-danger py-2"></div>
                                            <input type="hidden" id="id-field" />
                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="first-name">Last Name<sup>*</sup></Form.Label>
                                                        <Form.Control type="text" name="last_name" id="first_name" placeholder="Enter First Name" 
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.last_name || ""}
                                                            isInvalid={
                                                                validation.touched.last_name && validation.errors.last_name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.last_name && validation.errors.last_name ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.last_name}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="last-name">First Name<sup>*</sup></Form.Label>
                                                        <Form.Control type="text" name="first_name" id="last-name" placeholder="Enter Last Name" 
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.first_name || ""}
                                                        isInvalid={
                                                            validation.touched.first_name && validation.errors.first_name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.first_name && validation.errors.first_name ? (
                                                        <Form.Control.Feedback type="invalid"><div>{validation.errors.first_name}</div></Form.Control.Feedback>
                                                    ) : null}
                                                        
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="email">Email<sup>*</sup> </Form.Label>
                                                        <Form.Control type="email" name="email" id="email" placeholder="Enter Email"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                            isInvalid={
                                                                validation.touched.email && validation.errors.email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.email && validation.errors.email ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.email}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        {/* <Form.Label htmlFor="file">Profile Image </Form.Label>
                                                        <Form.Control type="file" id="file" /> */}
                                                        <Form.Label htmlFor="file">Profile Image</Form.Label>
                            <Form.Control 
                                type="file" 
                                id="file" 
                                onChange={handleFileChange}
                            />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="password">Password<sup>*</sup></Form.Label>
                                                        <Form.Control type="password" name="password" id="password" placeholder="Enter Password" 
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.password || ""}
                                                            isInvalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.password}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="verify-password">Confirm Password<sup>*</sup></Form.Label>
                                                        <Form.Control type="password" name='confirm_password' id="verify-password" placeholder="Enter Password" 
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.confirm_password || ""}
                                                            isInvalid={
                                                                validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.confirm_password}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
<Form.Label htmlFor="users_status">Status</Form.Label>
    <Form.Control 
        as="select" 
        id="users_status" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.users_status || ""}
        isInvalid={
            validation.touched.users_status && validation.errors.users_status ? true : false
        }
    >
        <option value="">Select Status</option>
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
    </Form.Control>
    </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="phone-number">Phone number<sup>*</sup> </Form.Label>
                                                        <Form.Control type="number" name="Phone_number" id="phone-number" placeholder="Enter Phone number" 
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.Phone_number || ""}
                                                            isInvalid={
                                                                validation.touched.Phone_number && validation.errors.Phone_number ? true : false
                                                            }
                                                        />
                                                        {validation.touched.Phone_number && validation.errors.Phone_number ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.Phone_number}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="telephone-number">Telephone number </Form.Label>
                                                        <Form.Control type="number" name="Telephone_number" id="telephone-number" placeholder="Enter Telephone number" 
                                                         onChange={validation.handleChange}
                                                         onBlur={validation.handleBlur}
                                                         value={validation.values.Telephone_number || ""}
                                                         isInvalid={
                                                             validation.touched.Telephone_number && validation.errors.Telephone_number ? true : false
                                                         }/>

{validation.touched.Telephone_number && validation.errors.Telephone_number ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.Telephone_number}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="address">Address </Form.Label>
                                                        <Form.Control type="text" name="Address" id="address" placeholder="Enter Address" 
                                                          onChange={validation.handleChange}
                                                          onBlur={validation.handleBlur}
                                                          value={validation.values.Address || ""}
                                                          isInvalid={
                                                              validation.touched.Address && validation.errors.Address ? true : false
                                                          }/>


                                                          
{validation.touched.Address && validation.errors.Address ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.Address}</div></Form.Control.Feedback>
                                                        ) : null}


                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="city">City </Form.Label>
                                                        <Form.Control type="text" name="City" id="city" placeholder="Enter City"
                                                          onChange={validation.handleChange}
                                                          onBlur={validation.handleBlur}
                                                          value={validation.values.City || ""}
                                                          isInvalid={
                                                              validation.touched.City && validation.errors.City ? true : false
                                                          } />

                                                          
{validation.touched.City && validation.errors.City ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.City}</div></Form.Control.Feedback>
                                                        ) : null}

                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="state">State </Form.Label>
                                                        <Form.Control type="text" name="State" id="state" placeholder="Enter State"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.State || ""}
                                                        isInvalid={
                                                            validation.touched.State && validation.errors.State ? true : false
                                                        } />
                                                        {validation.touched.State && validation.errors.State ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.State}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="zipcode">Zipcode </Form.Label>
                                                        <Form.Control type="number" name="Zipcode" id="Zipcode" placeholder="Enter Zipcode"
                                                         onChange={validation.handleChange}
                                                         onBlur={validation.handleBlur}
                                                         value={validation.values.Zipcode || ""}
                                                         isInvalid={
                                                             validation.touched.Zipcode && validation.errors.Zipcode ? true : false
                                                         } />
                                                          {validation.touched.Zipcode && validation.errors.Zipcode ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.Zipcode}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="country">Country </Form.Label>
                                                        <Form.Control type="text" name="Country" id="country" placeholder="Enter Country" 
                                                         onChange={validation.handleChange}
                                                         onBlur={validation.handleBlur}
                                                         value={validation.values.Country || ""}
                                                         isInvalid={
                                                             validation.touched.Country && validation.errors.Country ? true : false
                                                         }/>

{validation.touched.Zipcode && validation.errors.Country ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.Country}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Modal.Body>
                                        <div className="modal-footer">
                                            <div className="hstack gap-2 justify-content-end">
                                                {/* <Button className="btn-ghost-danger" onClick={() => { tog_AddCustomersModals(); }}>Close</Button> */}
                                                <Button variant='success' type='submit' id="add-btn">Add Customers</Button>
                                            </div>
                                        </div>
                                    </Form>
                                
                            </Modal>

                            <Card>
                                <Card.Body className='p-0'>
                                    {/* <div className="table-responsive table-card mb-1"> */}
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                        <ListViewTable allcustomerslist={customerslist} fetchcustomersLists={fetchcustomersList} />
                                        </table>
                                        <div className="noresult" style={{ display: "none" }}>
                                            <div className="text-center py-4">
                                                <div className="avatar-md mx-auto mb-4">
                                                    <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-24">
                                                        <i className="bi bi-search"></i>
                                                    </div>
                                                </div>
                                                <h5 className="mt-2">Sorry! No Result Found</h5>
                                                <p className="text-muted mb-0">We've searched more than 150+ seller We did not find any seller for you search.</p>
                                            </div>
                                        </div>
                                    {/* </div> */}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* { <ListViewTable termsDataa={validation.values} /> } */}
        </React.Fragment>
    );
}






export default CustomersListView;






// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import React, { useState } from 'react';
// import { Button, Card, Col, Form, Row } from 'react-bootstrap';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import Flatpickr from "react-flatpickr";
// import axios from 'axios';
// import * as Yup from 'yup';
// import { useFormik } from 'formik';

// const PersonalInformation = () => {
//     const [loader, setLoader] = useState<boolean>(false);
//     const [profileImage, setProfileImage] = useState<File | null>(null);

//     const validation = useFormik({
//         enableReinitialize: true,
//         initialValues: {
//             email: '',
//             last_name: '',
//             first_name: '',
//             password: '',
//             confirm_password: '',
//             Phone_number: '',
//             Telephone_number: '',
//             Address: '',
//             City: '',
//             State: '',
//             Zipcode: '',
//             Country: '',
//             About: '',
//             Personal_statement: '',
//             Personal_interest: '',
//             Website: '',
//             Designation: ''
//         },
//         validationSchema: Yup.object({
//             email: Yup.string().required("Please Enter Your Email"),
//             first_name: Yup.string().required("Please Enter Your First Name"),
//             last_name: Yup.string().required("Please Enter Your Last Name"),
//             password: Yup.string().required("Please Enter Your Password")
//                 .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"),
//             confirm_password: Yup.string().oneOf([Yup.ref('password'), undefined], 'Password must match')
//                 .required("Please Enter Your Password"),
//             Phone_number: Yup.string().required("Please Enter Your Mobile Number")
//                 .matches(/^\d{10}$/, "Phone Number must be 10 digits"),
//         }),
//         onSubmit: async (values) => {
//             console.log(values, "Form Values");
//             setLoader(true);
//             try {
//                 const formData = new FormData();
//                 Object.keys(values).forEach(key => {
//                     formData.append(key, (values as any)[key]);
//                 });
//                 if (profileImage) {
//                     formData.append('Profile_Image', profileImage);
//                 }

//                 const tokenString = localStorage.getItem('authUser');
//                 let token = '';

//                 if (tokenString) {
//                     const tokenObject = JSON.parse(tokenString);
//                     token = tokenObject.token;
//                 }

//                 await axios.post(
//                     `${process.env.REACT_APP_API_BASEURL}/auth/add_artist`,
//                     formData,
//                     {
//                         headers: {
//                             'Content-Type': 'multipart/form-data',
//                             'Authorization': `Bearer ${token}`,
//                         },
//                     }
//                 );
//                 validation.resetForm();
//             } catch (error) {
//                 console.error(error);
//             } finally {
//                 setLoader(false);
//             }
//         },
//     });

//     const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//         if (event.target.files && event.target.files[0]) {
//             setProfileImage(event.target.files[0]);
//         }
//     };

//     return (
//         <React.Fragment>
//             <Row>
//                 <Col lg={4}>
//                     <h5 className="fs-16">Personal Information</h5>
//                     <p className="text-muted mb-lg-0">Personal Identifiable Information (PII) is defined as: Any representation of information that permits the identity of an individual to whom the information applies to be reasonably inferred by either direct or indirect means.</p>
//                 </Col>
//                 <Col lg={8}>
//                     <Card>
//                         <Card.Body>
//                             <Form onSubmit={validation.handleSubmit}>
//                                 <Row className="g-3">
//                                     <Col lg={6}>
//                                         <Form.Label htmlFor="firstName">First Name <span className="text-danger">*</span></Form.Label>
//                                         <Form.Control type="text" id="firstName" placeholder="Enter your first name" {...validation.getFieldProps('first_name')} />
//                                     </Col>
//                                     <Col lg={6}>
//                                         <Form.Label htmlFor="lastName">Last Name <span className="text-danger">*</span></Form.Label>
//                                         <Form.Control type="text" id="lastName" placeholder="Enter your last name" {...validation.getFieldProps('last_name')} />
//                                     </Col>
//                                     <Col lg={6}>
//                                         <Form.Label htmlFor="emailInput">Email Address <span className="text-danger">*</span></Form.Label>
//                                         <Form.Control type="text" id="emailInput" placeholder="name@example.com" {...validation.getFieldProps('email')} />
//                                     </Col>
//                                     <Col lg={6}>
//                                         <Form.Label htmlFor="phoneInput">Phone Number</Form.Label>
//                                         <Form.Control type="text" id="phoneInput" placeholder="Enter phone number" {...validation.getFieldProps('Phone_number')} />
//                                     </Col>
//                                     <Col lg={6}>
//                                         <Form.Label htmlFor="telephoneInput">Telephone Number</Form.Label>
//                                         <Form.Control type="text" id="telephoneInput" placeholder="Enter telephone number" {...validation.getFieldProps('Telephone_number')} />
//                                     </Col>
//                                     <Col lg={6}>
//                                         <Form.Label htmlFor="addressInput">Address</Form.Label>
//                                         <Form.Control type="text" id="addressInput" placeholder="Enter address" {...validation.getFieldProps('Address')} />
//                                     </Col>
//                                     <Col lg={6}>
//                                         <Form.Label htmlFor="cityInput">City</Form.Label>
//                                         <Form.Control type="text" id="cityInput" placeholder="Enter city" {...validation.getFieldProps('City')} />
//                                     </Col>
//                                     <Col lg={6}>
//                                         <Form.Label htmlFor="stateInput">State</Form.Label>
//                                         <Form.Control type="text" id="stateInput" placeholder="Enter state" {...validation.getFieldProps('State')} />
//                                     </Col>
//                                     <Col lg={6}>
//                                         <Form.Label htmlFor="zipcodeInput">Zip Code</Form.Label>
//                                         <Form.Control type="text" id="zipcodeInput" placeholder="Enter zip code" {...validation.getFieldProps('Zipcode')} />
//                                     </Col>
//                                     <Col lg={6}>
//                                         <Form.Label htmlFor="countryInput">Country</Form.Label>
//                                         <Form.Control type="text" id="countryInput" placeholder="Enter country" {...validation.getFieldProps('Country')} />
//                                     </Col>
//                                     <Col lg={6}>
//                                         <Form.Label htmlFor="passwordInput">Password</Form.Label>
//                                         <Form.Control type="password" id="passwordInput" placeholder="Enter password" {...validation.getFieldProps('password')} />
//                                     </Col>
//                                     <Col lg={6}>
//                                         <Form.Label htmlFor="confirmPasswordInput">Confirm Password</Form.Label>
//                                         <Form.Control type="password" id="confirmPasswordInput" placeholder="Confirm password" {...validation.getFieldProps('confirm_password')} />
//                                     </Col>
//                                     <Col lg={12}>
//                                         <Form.Label>About</Form.Label>
//                                         <CKEditor
//                                             editor={ClassicEditor}
//                                             data="<p>Enter your description here.</p>"
//                                             onChange={(event:any, editor:any) => {
//                                                 validation.setFieldValue('About', editor.getData());
//                                             }}
//                                         />
//                                     </Col>
//                                     <Col lg={12}>
//                                         <Form.Label>Personal Statement</Form.Label>
//                                         <CKEditor
//                                             editor={ClassicEditor}
//                                             data="<p>Enter your personal statement here.</p>"
//                                             onChange={(event:any, editor:any) => {
//                                                 validation.setFieldValue('Personal_statement', editor.getData());
//                                             }}
//                                         />
//                                     </Col>
//                                     <Col lg={12}>
//                                         <Form.Label>Personal Interest</Form.Label>
//                                         <CKEditor
//                                             editor={ClassicEditor}
//                                             data="<p>Enter your personal interests here.</p>"
//                                             onChange={(event:any, editor:any) => {
//                                                 validation.setFieldValue('Personal_interest', editor.getData());
//                                             }}
//                                         />
//                                     </Col>
//                                     <Col lg={6}>
//                                         <Form.Label htmlFor="websiteInput">Website</Form.Label>
//                                         <Form.Control type="text" id="websiteInput" placeholder="Enter website" {...validation.getFieldProps('Website')} />
//                                     </Col>
//                                     <Col lg={6}>
//                                         <Form.Label htmlFor="designationInput">Designation</Form.Label>
//                                         <Form.Control type="text" id="designationInput" placeholder="Enter designation" {...validation.getFieldProps('Designation')} />
//                                     </Col>
//                                     <Col lg={12}>
//                                         <Form.Label htmlFor="profileImageInput">Profile Image</Form.Label>
//                                         <Form.Control type="file" id="profileImageInput" onChange={handleFileChange} />
//                                     </Col>
//                                     <Col lg={12}>
//                                         <Button type="submit" disabled={loader}>{loader ? 'Submitting...' : 'Submit'}</Button>
//                                     </Col>
//                                 </Row>
//                             </Form>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>
//         </React.Fragment>
//     );
// };

// export default PersonalInformation;
