
import React, { useState, useMemo } from 'react';
import TableContainer from "Common/TableContainer";
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';





import { Button, Card, Col, Container, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';


import { useFormik } from 'formik';
import * as Yup from 'yup';


interface ListViewTableProps {
    allcustomerslist: any;
    fetchcustomersLists:any
    
}


interface FormValues {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
   
    Phone_number: string;
    Profile_Image: any;
    Telephone_number: string;
    Address: string;
    City: string;
    State: string;
    Zipcode: string;
    Country: string;
    users_status?: string; // optional if not always required
    user_role?: string; // optional if not always required
}


const ListViewTable: React.FC<ListViewTableProps> = ({ allcustomerslist, fetchcustomersLists}) => {
    const [allcustomers, setAllcustomers] = useState<any>(null);
    const [loader, setLoader] = useState<boolean>(false);
    const [modal_AddSellerModals, setModalAddSellerModals] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [modal_DeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);

    const navigate = useNavigate();

    const tokenString = localStorage.getItem('authUser');
    let token = '';

    if (tokenString) {
        const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
        token = tokenObject.token;
    }

    const fetchcustomersDetailsById = async (id: any) => {
        console.log("Selected ID:", id);
    
        setLoader(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_customer_detailsby_id`,
                {
                    params: { id },
                    headers: {
                      
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );


            const processedData = response.data.user?.map((item: any) => {
                const authUser = response.data.authuser?.find((uu: any) => uu.user_id === item.id);
                return {
                    id: item.id,
                    first_name: item.first_name,
                    last_name: item.last_name,
                    email: authUser?.email || '',
                    status: item.user_status,
                    user_id: '1'
                };
            }) || [];
            console.log( )
            navigate(`/recent-orders?id=${id}`, { state: { data: processedData } });
            setAllcustomers(response.data);
    

            
          
          
          
        } catch (error) {
            console.error(error);
        } finally {
            setLoader(false);
        }
    };
    
    const customersList = useMemo(() => {
        if (!allcustomers) return [];
        return allcustomers.user?.map((item: any) => {
            const authUser = allcustomers.authuser?.find((uu: any) => uu.user_id === item.id);
            return {
                id: item.id,
                first_name: item.first_name,
                last_name: item.last_name,
                email: authUser?.email || '',
                status: item.user_status,
                user_id: '1'
            };
        }) || [];
    }, [allcustomers]);



    const handleDeleteClick = (item: any) => {
        setSelectedItem(item);
        setModalDeleteConfirm(true);
    };

    const confirmDelete = async () => {
        
        try {
            if (selectedItem) {
                setLoader(true);
                await axios.get(
                    `${process.env.REACT_APP_API_BASEURL}/auth/delete_customer`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        params: {
                            id: selectedItem.id,
                        },
                    }
                );
                
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoader(false);
            setModalDeleteConfirm(false);
            fetchcustomersLists()
        }
    };


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id:'',
            last_name: '',
            email:'',
            first_name: '',
          
            users_status:'',
            user_role:'',
           
            Phone_number: '',
            Profile_Image: '',
            Telephone_number: '', 
            Address:  '', 
            City: '',
            State: '',
            Zipcode: '', 
            Country:  '', 
        },
        validationSchema: Yup.object({
        
        }),
        onSubmit: async (values) => {
            console.log( values, "55555")
            setLoader(true);
            try {
                const formData = new FormData();
                Object.keys(values).forEach(key => {
                    if (key === 'Profile_Image' && values[key]) {
                        formData.append(key, values[key]);
                    } else {
                        formData.append(key, (values as any)[key]);
                    }
                });

                const tokenString = localStorage.getItem('authUser');
                let token = '';

                if (tokenString) {
                    const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
                    token = tokenObject.token;
                }

                await axios.post(
                    `${process.env.REACT_APP_API_BASEURL}/auth/edit_customer`,
                    formData,

                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
                setModalAddSellerModals(false);
                validation.resetForm();
                fetchcustomersLists()
            } catch (error) {
                console.error(error);
            } finally {
                setLoader(false);
            }
        },
    });
    
    const handleEditClick = (cellProps: any) => {
        const item = cellProps;
        setSelectedItem(item);
        validation.setValues({
            id: item.id || '',
            first_name: item.first_name || '',
            last_name: item.last_name || '',
            email: item.email || '',
            Phone_number: item.Phone_number || '',
            users_status: item.users_status || '',
            Profile_Image: item.Profile_Image || null,
            user_role: item.user_role || '',
            Address: item.Address || '',
            Telephone_number: item.Telephone_number || '',
            City: item.City || '',
            State: item.State || '',
            Zipcode: item.Zipcode || '',
            Country: item.Country || '',
        });
        setModalAddSellerModals(true);
    };
    
    
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            validation.setFieldValue('Profile_Image', event.target.files[0]);
        }
    };
    const columns = useMemo(
        () => [
            {
                Header: (
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                    </div>
                ),
                Cell: (cellProps: any) => (
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="chk_child" defaultValue="option1" />
                    </div>
                ),
                id: '#',
            },
            {
                Header: "First Name",
                accessor: "first_name",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Last Name",
                accessor: "last_name",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Email",
                accessor: "email",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Created At",
                accessor: "created_at",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Account Status",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps: any) => {
                    switch (cellProps.users_status) {
                        case "Active":
                            return (<span className="badge bg-success-subtle text-success text-uppercase">{cellProps.users_status}</span>);
                        case "Inactive":
                            return (<span className="badge bg-danger-subtle text-danger text-uppercase">{cellProps.users_status}</span>);
                        default:
                            return (<span className="badge bg-success-subtle text-success text-uppercase">{cellProps.users_status}</span>);
                    }
                },
            },
            {
                Header: "Action",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps: any) => {
                    const rowData = cellProps.row ? cellProps.row.original : {};
                    return (
                        <div className="d-flex gap-2">
                            <div>
                                <Button
                                    onClick={() => fetchcustomersDetailsById(cellProps.id)} // Pass the id directly
                                    className="btn btn-sm btn-soft-secondary"
                                >
                                    View
                                </Button>
                            </div>
                            <div className="edit">
                                <Button
                                    onClick={() => handleEditClick(cellProps)}
                                    className="btn btn-sm btn-soft-info"
                                >
                                    Edit
                                </Button>
                            </div>
                            <div className="remove">
                                <Button
                                    variant='soft-danger'
                                    size="sm"
                                    className="remove-item-btn"
                                    onClick={() => handleDeleteClick(cellProps)}
                                >
                                    Remove
                                </Button>
                            </div>
                        </div>
                    );
                },
            },
        ],
        [customersList, navigate]
    );

    return (
        <React.Fragment>
            <TableContainer
                columns={columns}
                sellerListss={allcustomerslist}
                data={customersList}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                className="custom-header-css table align-middle table-nowrap"
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                SearchPlaceholder='Search Products...'
            />


<Modal className="fade addAdmin" show={modal_AddSellerModals} onHide={() => setModalAddSellerModals(false)} centered>
                <Modal.Header className="px-4 pt-4" closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">Edit Customers</h5>
                </Modal.Header>
                <Form className="tablelist-form" onSubmit={validation.handleSubmit}>
                    <Modal.Body className="p-4">
                        <div id="alert-error-msg" className="d-none alert alert-danger py-2"></div>
                        <Row>
                          


                        <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="last_name">Last Name<sup>*</sup></Form.Label>
                                                        <Form.Control type="text" name="last_name" id="last_name" placeholder="Enter last_namee" 
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.last_name || ""}
                                                            isInvalid={
                                                                validation.touched.last_name && validation.errors.last_name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.last_name && validation.errors.last_name ? (
                                                             <Form.Control.Feedback type="invalid"><div>{validation.errors.last_name}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>



                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="first_name">First Name<sup>*</sup></Form.Label>
                                                        <Form.Control type="text" name="first_name" id="first_name" placeholder="Enter First Name" 
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.first_name || ""}
                                                        isInvalid={
                                                            validation.touched.first_name && validation.errors.first_name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.first_name && validation.errors.first_name ? (
                                                        <Form.Control.Feedback type="invalid"><div>{validation.errors.first_name}</div></Form.Control.Feedback>
                                                    ) : null}
                                                        
                                                    </div>
                                                </Col>


                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="email">Email<sup>*</sup> </Form.Label>
                                                        <Form.Control type="email" name="email" id="email" placeholder="Enter Email"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                            isInvalid={
                                                                validation.touched.email && validation.errors.email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.email && validation.errors.email ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.email}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        {/* <Form.Label htmlFor="file">Profile Image </Form.Label>
                                                        <Form.Control type="file" id="file" /> */}
                                                        <Form.Label htmlFor="file">Profile Image</Form.Label>
                            <Form.Control 
                                type="file" 
                                id="file" 
                                onChange={handleFileChange}
                            />
                                                    </div>
                                                </Col>
                                               
                                                <Col md={6}>
                                                <div className="mb-3">
    <Form.Label htmlFor="users_status">Status</Form.Label>
    <Form.Control 
        as="select" 
        id="users_status" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.users_status || ""}
        isInvalid={
            validation.touched.users_status && validation.errors.users_status ? true : false
        }
    >
        <option value="">Select Status</option>
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
    </Form.Control>
</div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="Phone_number">Phone number<sup>*</sup> </Form.Label>
                                                        <Form.Control type="number" name="Phone_number" id="Phone_number" placeholder="Enter Phone number" 
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.Phone_number || ""}
                                                            isInvalid={
                                                                validation.touched.Phone_number && validation.errors.Phone_number ? true : false
                                                            }
                                                        />
                                                        {validation.touched.Phone_number && validation.errors.Phone_number ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.Phone_number}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="Telephone_number">Telephone number </Form.Label>
                                                        <Form.Control type="number" name="Telephone_number" id="Telephone_number" placeholder="Enter Telephone number" 
                                                         onChange={validation.handleChange}
                                                         onBlur={validation.handleBlur}
                                                         value={validation.values.Telephone_number || ""}
                                                         isInvalid={
                                                             validation.touched.Telephone_number && validation.errors.Telephone_number ? true : false
                                                         }/>

{validation.touched.Telephone_number && validation.errors.Telephone_number ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.Telephone_number}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="Address">Address </Form.Label>
                                                        <Form.Control type="text" name="Address" id="Address" placeholder="Enter Address" 
                                                          onChange={validation.handleChange}
                                                          onBlur={validation.handleBlur}
                                                          value={validation.values.Address || ""}
                                                          isInvalid={
                                                              validation.touched.Address && validation.errors.Address ? true : false
                                                          }/>


                                                          
{validation.touched.Address && validation.errors.Address ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.Address}</div></Form.Control.Feedback>
                                                        ) : null}


                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="City">City </Form.Label>
                                                        <Form.Control type="text" name="City" id="City" placeholder="Enter City"
                                                          onChange={validation.handleChange}
                                                          onBlur={validation.handleBlur}
                                                          value={validation.values.City || ""}
                                                          isInvalid={
                                                              validation.touched.City && validation.errors.City ? true : false
                                                          } />

                                                          
{validation.touched.City && validation.errors.City ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.City}</div></Form.Control.Feedback>
                                                        ) : null}

                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="State">State </Form.Label>
                                                        <Form.Control type="text" name="State" id="State" placeholder="Enter State"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.State || ""}
                                                        isInvalid={
                                                            validation.touched.State && validation.errors.State ? true : false
                                                        } />
                                                        {validation.touched.State && validation.errors.State ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.State}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="Zipcode">Zipcode </Form.Label>
                                                        <Form.Control type="number" name="Zipcode" id="Zipcode" placeholder="Enter Zipcode"
                                                         onChange={validation.handleChange}
                                                         onBlur={validation.handleBlur}
                                                         value={validation.values.Zipcode || ""}
                                                         isInvalid={
                                                             validation.touched.Zipcode && validation.errors.Zipcode ? true : false
                                                         } />
                                                          {validation.touched.Zipcode && validation.errors.Zipcode ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.Zipcode}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="Country">Country </Form.Label>
                                                        <Form.Control type="text" name="Country" id="Country" placeholder="Enter Country" 
                                                         onChange={validation.handleChange}
                                                         onBlur={validation.handleBlur}
                                                         value={validation.values.Country || ""}
                                                         isInvalid={
                                                             validation.touched.Country && validation.errors.Country ? true : false
                                                         }/>

{validation.touched.Zipcode && validation.errors.Country ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.Country}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                        </Row>
                        </Modal.Body>
                                        <div className="modal-footer">
                                            <div className="hstack gap-2 justify-content-end">
                                                {/* <Button className="btn-ghost-danger" onClick={() => { tog_AddSellerModals(); }}>Close</Button> */}
                                                <Button variant='success' type='submit' id="add-btn">Edit Customers</Button>
                                            </div>
                                        </div>
                                    </Form>
                                
                            </Modal>


                            
            <Modal show={modal_DeleteConfirm} onHide={() => setModalDeleteConfirm(false)} centered>
    <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <p>Are you sure you want to delete this item?</p>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalDeleteConfirm(false)}>
            Cancel
        </Button>
        <Button variant="danger" onClick={confirmDelete}>
            Delete
        </Button>
    </Modal.Footer>
</Modal>
        </React.Fragment>
    );
};

export default ListViewTable;








