
import React, { useMemo, useState, useEffect } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import TableContainer from "Common/TableContainer";
import CustomDropdownToggle from 'Common/CustomDropdownToggle';
import { Link, useLocation } from 'react-router-dom';

const Artist = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const dataParam = queryParams.get('yy');
    console.log(dataParam, "yyyyyyyyyyyyyyy" )
    const allpartners = location?.state?.yy; 
// console.log(allpartners, "nnnnnnnnnnnnnnnnnnnnnnnnnn" )


    const [data, setData] = useState<any[]>([]);
const dd= [dataParam]
useEffect(() => {
  if (dataParam) {
      try {
          const parsedData = JSON.parse(decodeURIComponent(dataParam));
          if (Array.isArray(parsedData)) {
              setData(parsedData);
          } else if (typeof parsedData === 'object' && parsedData !== null) {
              setData([parsedData]);
          } else {
              console.error('Parsed data is not valid');
          }
      } catch (e) {
          console.error('Failed to parse data:', e);
      }
  }
}, [dataParam]);

    const columns = useMemo(
        () => [
            // {
            //     Header: "Order ID",
            //     disableFilters: true,
            //     filterable: true,
            //     accessor: (cellProps: any) => {
            //         return <Link to="/" className="fw-medium link-primary">{cellProps.purchaseID}</Link>;
            //     },
            // },
            {
                Header: "first_name",
                accessor: "first_name",
                disableFilters: true,
                filterable: true,
            },
            // {
            //     Header: "Customer Name",
            //     disableFilters: true,
            //     filterable: true,
            //     accessor: (cellProps: any) => {
            //         return (
            //             <React.Fragment>
            //                 <div className="d-flex align-items-center">
            //                     <div className="flex-shrink-0 me-2">
            //                         <img src={cellProps.productImage} alt="" className="avatar-xxs rounded-circle" />
            //                     </div>
            //                     <div className="flex-grow-1">{cellProps.productName}</div>
            //                 </div>
            //             </React.Fragment>
            //         );
            //     },
            // },
            {
                Header: "Last Name",
                accessor: "last_name",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Email",
                accessor: "email",
                disableFilters: true,
                filterable: true,
            },
            // {
            //     Header: "Delivery Date",
            //     accessor: "deliveryDate",
            //     disableFilters: true,
            //     filterable: true,
            // },
            // {
            //     Header: "Vendor",
            //     accessor: "vendor",
            //     disableFilters: true,
            //     filterable: true,
            // },
            {
                Header: "Rating",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps: any) => {
                    return (
                        <React.Fragment>
                            {cellProps.rating} <i className="bi bi-star-half ms-1 text-warning fs-12"></i>
                        </React.Fragment>
                    );
                }
            },
            {
                Header: "Status",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps: any) => {
                    switch (cellProps.status) {
                        case "Paid":
                            return (<span className="badge bg-success-subtle text-success"> {cellProps.status}</span>);
                        case "Unpaid":
                            return (<span className="badge bg-danger-subtle text-danger"> {cellProps.status}</span>);
                        case "Pending":
                            return (<span className="badge bg-warning-subtle text-warning"> {cellProps.status}</span>);
                        default:
                            return (<span className="badge bg-success-subtle text-success"> {cellProps.status}</span>);
                    }
                },
            },
        ],
        []
    );

 
        return (
            <React.Fragment>
                 <h1></h1>
            
                <Col xxl={12}>
               
                {allpartners?.map((item: any, index: number) => (
                    <Card className="mb-4" key={index}>
                        <Card.Body>
                            <Row className="align-items-center text-center"> 
                                <Col xs={12}>
                                    {/* <img
                                        src="path_to_image.jpg"  // Replace with actual image path or use item.profile_image if available
                                        alt="User"
                                        className="img-fluid rounded-circle mb-3"
                                        style={{ width: '100px', height: '100px' }}
                                    /> */}
                                    <h4 className="mb-1">{item.first_name}</h4>
                                    <p className="mb-0" style={{ color: 'lightblue' }}>
                                        <i className="bi bi-envelope"></i> {item.email}
                                    </p>
                                    {/* <p className="mb-0" style={{ color: 'lightblue' }}>
                                        <i className="bi bi-telephone"></i> {item.Phone_number}
                                    </p> */}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                ))}
                    <Card>
                        <Card.Header className="align-items-center d-flex">
                         
                            <h4 className="card-title mb-0 flex-grow-1">Recent Orders</h4>
                        
                            <div className="flex-shrink-0">
                                <Dropdown className="card-header-dropdown">
                                    <Dropdown.Toggle as={CustomDropdownToggle} className="text-reset dropdown-btn">
                                        <span className="fw-semibold text-uppercase fs-12">Sort by:</span>
                                        <span className="text-muted"> Today<i className="mdi mdi-chevron-down ms-1"></i></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu align="start" className="dropdown-menu-end">
                                        <Dropdown.Item href="#">Today</Dropdown.Item>
                                        <Dropdown.Item href="#">Yesterday</Dropdown.Item>
                                        <Dropdown.Item href="#">Last 7 Days</Dropdown.Item>
                                        <Dropdown.Item href="#">Last 30 Days</Dropdown.Item>
                                        <Dropdown.Item href="#">This Month</Dropdown.Item>
                                        <Dropdown.Item href="#">Last Month</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Card.Header>
    
                        <TableContainer
                            columns={columns}
                            data={allpartners}
                            isGlobalFilter={false}
                            iscustomPageSize={false}
                            isBordered={false}
                            customPageSize={6}
                            className="custom-header-css table-card"
                            tableClass="table-centered align-middle table-nowrap mb-0"
                            theadClass="text-muted table-light"
                            SearchPlaceholder='Search Products...'
                        />
                    </Card>
                </Col>
            </React.Fragment>
        );
    };
    
    export default Artist;
