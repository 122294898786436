
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import dropdown from 'assets/images/dropdown.png'
import deleteicon from 'assets/images/delete icon.png';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import { useFormik } from "formik";
import * as Yup from 'yup';
import React, { useState } from 'react';
import Flatpickr from "react-flatpickr";

const PersonalInformation = () => {
    const [loader, setLoader] = useState<boolean>(false);
    const [galleries, setGalleries] = useState([{ name: '' }]);
    const handleCancel = () => {
        // Add your cancel logic here
        console.log('Cancel button clicked');
      };
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            // slug:'',
            // gender:'',
           
            email: '',
            status:'',
            Date_Of_Birth:"",
            Gender:'',



            last_name: '',
            first_name: '',
           
            Phone_number: '',
            Profile_Image: null,
            Telephone_number: '',
            Address: '',
            City: '',
            State: '',
            Zipcode: '',
            Country: '',
            designation: '',
            Blog_URL:'',
            User:'',
            Seo_Title:'',
            Seo_Description:'',
            Selection_of_Enquire_for_Orginals:'',
            Commission_work_for_This_Artist:'',
            Artist_thumbnail_desktop_Image:'',
            Artist_thumbnail_Mobile_Image:'',
            Photo_Essay_Title:'',
            Photo_Essay_Description:'',
            Essay_Title_1:'',
            Essay_Image_One:'',
            Essay_One_Description:'',
            Essay_Title_2:'',
            Essay_Image_Two:'',
            Essay_Two_Description:'',
            Essay_Title_Three:'',
            Essay_Image_Three:'',
            Essay_Three_Description:'',
            Essay_Title_4:'',
            Essay_Image_Four:'',
            Essay_Four_Description:'',
            Essay_Title_5:'',
            Essay_Image_Five:'',
            Essay_Five_Description:'',
            Essay_Title_6:'',
            Essay_Image_Six:'',
            Essay_Six_Description:'',
            // Association_with_art_galleries:'',
        },
        validationSchema: Yup.object({
            // Add your validation rules here if needed
        }),
        onSubmit: async (values) => {
            console.log(values, "Submitted Values");
            setLoader(true);
            try {
                const formData = new FormData();
                Object.keys(values).forEach(key => {
                    if (key === 'Profile_Image' && values[key]) {
                        formData.append(key, values[key]);
                    } else {
                        formData.append(key, (values as any)[key]);
                    }
                });

                const tokenString = localStorage.getItem('authUser');
                let token = '';

                if (tokenString) {
                    const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
                    token = tokenObject.token;
                }

                await axios.post(
                    `${process.env.REACT_APP_API_BASEURL}/auth/add_artist`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
                validation.resetForm();
            } catch (error) {
                console.error(error);
            } finally {
                setLoader(false);
            }
        },
    });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            validation.setFieldValue('Profile_Image', event.target.files[0]);
        }
    };
    const handleGalleryChange = (index:any, event:any) => {
        const newGalleries = [...galleries];
        newGalleries[index].name = event.target.value;
        setGalleries(newGalleries);
        validation.setFieldValue('Association_with_art_galleries', newGalleries);
    };

    const handleAddGallery = () => {
        const updatedGalleries = [...galleries, { name: '' }];
        setGalleries(updatedGalleries);
        validation.setFieldValue('Association_with_art_galleries', updatedGalleries.map(g => g.name).join(','));
    };
    
    const handleRemoveGallery = (index:any) => {
        const updatedGalleries = galleries.filter((_, i) => i !== index);
        setGalleries(updatedGalleries);
        validation.setFieldValue('Association_with_art_galleries', updatedGalleries.map(g => g.name).join(','));
    };
    
    return (
        <React.Fragment>
            <Row>
                <Col lg={4}>
                    <h5 className="fs-16">Personal Information</h5>
                    <p className="text-muted mb-lg-0">Personal Identifiable Information (PII) is defined as: Any representation of information that permits the identity of an individual to whom the information applies to be reasonably inferred by either direct or indirect means.</p>
                </Col>
                <Col lg={8}>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={validation.handleSubmit}>
                                <Row className="g-3">
                                    {/* Existing fields */}
                                    <Col lg={6}>
                                        <div>
                                            <Form.Label htmlFor="firstName">First Name <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="firstName"
                                                name="first_name"
                                                placeholder="Enter your first name"
                                                value={validation.values.first_name}
                                                onChange={validation.handleChange}
                                                isInvalid={!!validation.errors.first_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validation.errors.first_name}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div>
                                            <Form.Label htmlFor="lastName">Last Name <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="lastName"
                                                name="last_name"
                                                placeholder="Enter your last name"
                                                value={validation.values.last_name}
                                                onChange={validation.handleChange}
                                                isInvalid={!!validation.errors.last_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validation.errors.last_name}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div>
                                            <Form.Label htmlFor="emailInput">Email Address <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="email"
                                                id="emailInput"
                                                name="email"
                                                placeholder="name@toner.com"
                                                value={validation.values.email}
                                                onChange={validation.handleChange}
                                                isInvalid={!!validation.errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validation.errors.email}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div>
                                            <Form.Label htmlFor="phoneInput">Phone Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="phoneInput"
                                                name="Phone_number"
                                                placeholder="Enter phone number"
                                                value={validation.values.Phone_number}
                                                onChange={validation.handleChange}
                                                isInvalid={!!validation.errors.Phone_number}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validation.errors.Phone_number}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div>
                                            <Form.Label htmlFor="birthdateInput">Date of Birth</Form.Label>
                                            <Flatpickr
                                                className="form-control flatpickr-input"
                                                placeholder='Select Date'
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "d M, Y",
                                                }}
                                                onChange={(date) => validation.setFieldValue('date_of_birth', date)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div>
                                            <Form.Label htmlFor="designationInput">Designation</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="designationInput"
                                                name="designation"
                                                placeholder="Designation"
                                                value={validation.values.designation}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>














                                    <Col lg={6}>
                                   
    <div>
        <Form.Label htmlFor="genderSelect">Gender</Form.Label>
        <Form.Select
            id="GenderSelect"
            name="Gender"
            value={validation.values.Gender || ""}
            onChange={validation.handleChange}
            isInvalid={!!validation.errors.Gender}
        >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
            {validation.errors.Gender}
        </Form.Control.Feedback>
    </div>
</Col>










<Col lg={6}>
                                   
                                   {/* <div>
                                       <Form.Label htmlFor="statusSelect">status</Form.Label>
                                       <Form.Select
                                           id="GenderSelect"
                                           name="status"
                                           value={validation.values.status || ""}
                                           onChange={validation.handleChange}
                                           isInvalid={!!validation.errors.status}
                                       >
                                           <option value="">Select status</option>
                                           <option value="Active">Active</option>
                                           <option value="Inactive">Inactive</option>
                                       </Form.Select>
                                       <Form.Control.Feedback type="invalid">
                                           {validation.errors.status}
                                       </Form.Control.Feedback>
                                   </div> */}





                               </Col>


                                 
                                    <Col lg={6}>
                                        <div>
                                            <Form.Label htmlFor="websiteInput1">Website</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="websiteInput1"
                                                name="website"
                                                placeholder="www.example.com"
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div>
                                            <Form.Label htmlFor="cityInput">City</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="cityInput"
                                                name="City"
                                                placeholder="City"
                                                value={validation.values.City}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div>
                                            <Form.Label htmlFor="countryInput">Country</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="countryInput"
                                                name="Country"
                                                placeholder="Country"
                                                value={validation.values.Country}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    {/* <Col lg={6}> */}
                                        <div>
                                            <Form.Label htmlFor="zipcodeInput">Zip Code</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="zipcodeInput"
                                                name="Zipcode"
                                                placeholder="Enter zipcode"
                                                value={validation.values.Zipcode}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    {/* </Col>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Form.Label>About</Form.Label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                            />
                                        </div>
                                    </Col> */}
                                    {/* <Col lg={12}>
                                        <div className="mb-3">
                                            <Form.Label>Personal Statement</Form.Label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                            />
                                        </div> */}
                                    {/* </Col> */}
                                    {/* <Col lg={12}>
                                        <div className="mb-3">
                                            <Form.Label>Personal Interest</Form.Label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                            />
                                        </div>
                                    </Col> */}
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Form.Label htmlFor="profileImage">Profile Image</Form.Label>
                                            <Form.Control
                                                type="file"
                                                id="profileImage"
                                                name="Profile_Image"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div>
                                            <Form.Label htmlFor="blogUrlInput">Blog URL</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="blogUrlInput"
                                                name="Blog_URL"
                                                placeholder="Enter blog URL"
                                                value={validation.values.Blog_URL}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div>
                                            <Form.Label htmlFor="seoTitleInput">SEO Title</Form.Label>
                                            <Form.Control
                                                 type="text"
                                                id="seoTitleInput"
                                                name="Seo_Title"
                                                placeholder="Enter SEO title"
                                                value={validation.values.Seo_Title}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div>
                                            <Form.Label htmlFor="seoDescriptionInput">SEO Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="seoDescriptionInput"
                                                name="Seo_Description"
                                                placeholder="Enter SEO description"
                                                value={validation.values.Seo_Description}
                                                onChange={validation.handleChange}
                                            />
                                        </div>























                                    </Col>
                                    <Col lg={6}>
                                        <div>
                                            <Form.Label htmlFor="enquireSelectionInput">Selection of Enquire for Originals</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="enquireSelectionInput"
                                                name="Selection_of_Enquire_for_Orginals"
                                                placeholder="Enter selection"
                                                value={validation.values.Selection_of_Enquire_for_Orginals}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="commissionWorkInput">Commission Work for This Artist</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="commissionWorkInput"
                                                name="Commission_work_for_This_Artist"
                                                placeholder="Enter commission work details"
                                                value={validation.values.Commission_work_for_This_Artist}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="desktopThumbnailImageInput">Artist Thumbnail Desktop Image</Form.Label>
                                            <Form.Control
                                                type="file"
                                                id="desktopThumbnailImageInput"
                                                name="Artist_thumbnail_desktop_Image"
                                                placeholder="Enter desktop thumbnail image URL"
                                                value={validation.values.Artist_thumbnail_desktop_Image}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="mobileThumbnailImageInput">Artist Thumbnail Mobile Image</Form.Label>
                                            <Form.Control
                                                type="file"
                                                id="mobileThumbnailImageInput"
                                                name="Artist_thumbnail_Mobile_Image"
                                                placeholder="Enter mobile thumbnail image URL"
                                                value={validation.values.Artist_thumbnail_Mobile_Image}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="photoEssayTitleInput">Photo Essay Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="photoEssayTitleInput"
                                                name="Photo_Essay_Title"
                                                placeholder="Enter photo essay title"
                                                value={validation.values.Photo_Essay_Title}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="photoEssayDescriptionInput">Photo Essay Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="photoEssayDescriptionInput"
                                                name="Photo_Essay_Description"
                                                placeholder="Enter photo essay description"
                                                value={validation.values.Photo_Essay_Description}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essayTitle1Input">Essay Title 1</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="essayTitle1Input"
                                                name="Essay_Title_1"
                                                placeholder="Enter essay title 1"
                                                value={validation.values.Essay_Title_1}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essayImageOneInput">Essay Image One</Form.Label>
                                            <Form.Control
                                                type="file"
                                                id="essayImageOneInput"
                                                name="Essay_Image_One"
                                                placeholder="Enter essay image one URL"
                                                value={validation.values.Essay_Image_One}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essayOneDescriptionInput">Essay One Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="essayOneDescriptionInput"
                                                name="Essay_One_Description"
                                                placeholder="Enter essay one description"
                                                value={validation.values.Essay_One_Description}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essayTitle2Input">Essay Title 2</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="essayTitle2Input"
                                                name="Essay_Title_2"
                                                placeholder="Enter essay title 2"
                                                value={validation.values.Essay_Title_2}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essayImageTwoInput">Essay Image Two</Form.Label>
                                            <Form.Control
                                                type="file"
                                                id="essayImageTwoInput"
                                                name="Essay_Image_Two"
                                                placeholder="Enter essay image two URL"
                                                value={validation.values.Essay_Image_Two}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essayTwoDescriptionInput">Essay Two Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="essayTwoDescriptionInput"
                                                name="Essay_Two_Description"
                                                placeholder="Enter essay two description"
                                                value={validation.values.Essay_Two_Description}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essayTitle3Input">Essay Title 3</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="essayTitle3Input"
                                                name="Essay_Title_Three"
                                                placeholder="Enter essay title 3"
                                                value={validation.values.Essay_Title_Three}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essayImageThreeInput">Essay Image Three</Form.Label>
                                            <Form.Control
                                                type="file"
                                                id="essayImageThreeInput"
                                                name="Essay_Image_Three"
                                                placeholder="Enter essay image three URL"
                                                value={validation.values.Essay_Image_Three}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essayThreeDescriptionInput">Essay Three Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="essayThreeDescriptionInput"
                                                name="Essay_Three_Description"
                                                placeholder="Enter essay three description"
                                                value={validation.values.Essay_Three_Description}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essayTitle4Input">Essay Title 4</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="essayTitle4Input"
                                                name="Essay_Title_4"
                                                placeholder="Enter essay title 4"
                                                value={validation.values.Essay_Title_4}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essayImageFourInput">Essay Image Four</Form.Label>
                                            <Form.Control
                                                type="file"
                                                id="essayImageFourInput"
                                                name="Essay_Image_Four"
                                                placeholder="Enter essay image four URL"
                                                value={validation.values.Essay_Image_Four}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essayFourDescriptionInput">Essay Four Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="essayFourDescriptionInput"
                                                name="Essay_Four_Description"
                                                placeholder="Enter essay four description"
                                                value={validation.values.Essay_Four_Description}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essayTitle5Input">Essay Title 5</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="essayTitle5Input"
                                                name="Essay_Title_5"
                                                placeholder="Enter essay title 5"
                                                value={validation.values.Essay_Title_5}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essayImageFiveInput">Essay Image Five</Form.Label>
                                            <Form.Control
                                                type="file"
                                                id="essayImageFiveInput"
                                                name="Essay_Image_Five"
                                                placeholder="Enter essay image five URL"
                                                value={validation.values.Essay_Image_Five}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essayFiveDescriptionInput">Essay Five Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="essayFiveDescriptionInput"
                                                name="Essay_Five_Description"
                                                placeholder="Enter essay five description"
                                                value={validation.values.Essay_Five_Description}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essayTitle6Input">Essay Title 6</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="essayTitle6Input"
                                                name="Essay_Title_6"
                                                placeholder="Enter essay title 6"
                                                value={validation.values.Essay_Title_6}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>


                                    <Col lg={12}>
                                    <div>
  <Form.Label>Association with art galleries</Form.Label>
  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <thead>
      <tr>
        <th style={{ textAlign: 'left', paddingBottom: '10px' }}>Name</th>
      </tr>
    </thead>
    <tbody>
      {galleries.map((gallery, index) => (
        <tr key={index}>
          <td style={{ paddingBottom: '10px', width: '80%' }}>
            <Form.Control
              type="text"
              placeholder=""
              value={gallery.name}
              onChange={(event) => handleGalleryChange(index, event)}
              style={{ width: '100%' }}
            />
          </td>
          <td style={{ paddingBottom: '10px', width: '10%', textAlign: 'center' }}>
            <Button variant="light" onClick={() => handleAddGallery()}>
              <img src={dropdown} alt="dropdown" height={18} width={18} />
            </Button>
          </td>
          <td style={{ paddingBottom: '10px', width: '10%', textAlign: 'center' }}>
            <Button
              variant="light"
              onClick={() => handleRemoveGallery(index)}
            >
              <img src={deleteicon} alt="delete" height={18} width={18} />
            </Button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  <Button variant="outline-primary" onClick={handleAddGallery}>
    + Add gallery
  </Button>
</div>

</Col>
                                        <div>
                                            <Form.Label htmlFor="essayImageSixInput">Essay Image Six</Form.Label>
                                            <Form.Control
                                                type="file"
                                                id="essayImageSixInput"
                                                name="Essay_Image_Six"
                                                placeholder="Enter essay image six URL"
                                                value={validation.values.Essay_Image_Six}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div>
                                            <Form.Label htmlFor="essaySixDescriptionInput">Essay Six Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="essaySixDescriptionInput"
                                                name="Essay_Six_Description"
                                                placeholder="Enter essay six description"
                                                value={validation.values.Essay_Six_Description}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        {/* <Button type="submit" variant="primary" disabled={loader}>
                                            {loader ? 'Submitting...' : 'Submit'}
                                        </Button> */}











<div>
      <Button
        variant="success"
        type="submit"
        style={{ marginTop: '20px', display: 'inline-flex', alignItems: 'center' }}
      >
        <i className="fas fa-save" style={{ marginRight: '5px' }}></i>
        Add
      </Button>
      <Button
        variant="outline-secondary"
        onClick={handleCancel}
        style={{ marginTop: '20px', marginLeft: '10px' }}
      >
        Cancel
      </Button>
    </div>
    {/* </Form>
  </Container>
); */}
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default PersonalInformation;

